<template>
  <v-select
    v-model="baseInstrumentOption"
    :options="baseInstrumentOptions"
    placeholder="Основная валюта портфеля"
    class="w-[200px] md:w-full"
  />
</template>

<script setup lang="ts">
import { Currency } from "~/models/api"
import { SelectOption } from "~/components/base/v-select/v-select.vue"

const baseInstrumentId = inject("baseInstrumentId") as Ref<Currency>
const baseInstrumentOptions: SelectOption<Currency>[] = [
  {
    label: "Рубли",
    value: Currency.RUB,
  },
  {
    label: "Доллары",
    value: Currency.USD,
  },
  {
    label: "Евро",
    value: Currency.EUR,
  },
]
const baseInstrumentOption = computed({
  get() {
    return baseInstrumentOptions.find((option) => option.value === baseInstrumentId.value) as SelectOption<Currency>
  },
  set(newValue: SelectOption<Currency>) {
    baseInstrumentId.value = newValue.value
  },
})
</script>
