<template>
  <div class="flex items-center gap-16">
    <v-back
      v-if="props.backButton"
      @click="emit('back')"
    />

    <span class="text-13 font-medium text-84849b">Шаг {{ props.step }} из {{ props.steps }}</span>

    <div class="relative top-1 h-4 grow overflow-hidden rounded-full bg-e6e7ec">
      <div
        class="absolute inset-y-0 left-0 h-full bg-2c58f3 transition-[width]"
        :style="{
          width: `${Math.ceil((step / steps) * 100)}%`,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  step: number
  steps: number
  backButton?: boolean
}>()

const emit = defineEmits<{
  (e: "back"): void
}>()
</script>
