<template>
  <slot
    name="trigger"
    :show-bottom-sheet="show"
  />

  <Transition>
    <div
      v-if="isVisible"
      class="fixed inset-0 z-30 cursor-pointer bg-black/40"
      @click="isVisible = false"
    ></div>
  </Transition>

  <Teleport to="body">
    <Transition
      name="slide-bottom"
      @after-enter="emit('afterEnter')"
    >
      <div
        v-if="isVisible"
        class="fixed bottom-0 left-0 right-0 z-[60] mx-auto flex max-h-[calc(100vh-130px)] max-w-[700px] flex-col rounded-t-30 bg-white"
        :class="{
          'h-full': props.fullHeight,
        }"
        :style="style"
      >
        <div class="absolute left-0 right-0 top-[calc(100%-1px)] h-[100vh] bg-white"></div>
        <div
          ref="handle"
          class="mx-auto w-[108px] p-16"
          :class="{
            'cursor-grabbing': isSwiping,
            'cursor-grab': !isSwiping,
          }"
        >
          <div class="h-6 rounded-30 bg-464646"></div>
        </div>
        <div
          v-scroll="() => emit('scroll')"
          class="overflow-auto px-16 pb-16"
        >
          <slot :hide-bottom-sheet="hide" />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { vScroll } from "@vueuse/components"

export interface VBottomSheetProps {
  modelValue?: boolean
  fullHeight?: boolean
}

const props = defineProps<VBottomSheetProps>()

const emit = defineEmits<{
  "update:modelValue": [value: boolean]
  scroll: []
  move: []
  afterEnter: []
}>()

const isVisible = ref(props.modelValue || false)
watchEffect(() => {
  isVisible.value = props.modelValue
})
watchEffect(() => {
  emit("update:modelValue", isVisible.value)
})

function hide() {
  isVisible.value = false
}

function show() {
  isVisible.value = true
}

const handle = ref<HTMLDivElement>()
const { isSwiping } = usePointerSwipe(handle, {
  threshold: 0,
  onSwipeStart(e: PointerEvent) {
    initialClientY.value = e.clientY
    currentClientY.value = e.clientY
    emit("move")
  },
  onSwipe(e: PointerEvent) {
    currentClientY.value = e.clientY
  },
})

const initialClientY = ref(0)
const currentClientY = ref(0)

watchEffect(() => {
  if (!isSwiping.value && initialClientY.value - currentClientY.value < -30) {
    isVisible.value = false
  }
})

const style = computed(() => {
  if (!isSwiping.value) {
    return {
      transition: "transform .3s",
    }
  }

  return {
    transform: `translateY(${currentClientY.value - initialClientY.value}px)`,
  }
})
</script>
