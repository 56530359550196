import { TradeInstrument } from "~/models/api"

export const useInstrumentsStore = defineStore("instruments", () => {
  const types: Record<string, string> = {}

  async function getTypes() {
    if (Object.values(types).length) {
      return
    }

    try {
      const response = await findInstrumentsTypes()
      for (const type of response.data.data) {
        types[type.id] = type.name
      }
    } catch {
      /** */
    }
  }

  const instruments: Record<string, TradeInstrument> = {}

  function reset() {
    for (const key in instruments) {
      delete instruments[key]
    }
  }

  async function loadInstrument(id: number | string) {
    return getInstrument(id)
  }

  async function getInstrument(id: number | string) {
    const storedInstrument = instruments[id]
    if (typeof storedInstrument !== "undefined") {
      return storedInstrument
    }

    const response = await findInstruments({ ids: [id] })
    const instrument = response.data?.data[0]
    saveInstrument(instrument)

    return instrument
  }

  async function reloadInstrument(id: string) {
    delete instruments[id]
    return await getInstrument(id)
  }

  function saveInstrument(instrument: TradeInstrument) {
    instruments[instrument.id] = instrument
  }

  return {
    reset,
    loadInstrument,
    reloadInstrument,
    getInstrument,
    saveInstrument,
    getTypes,
    types,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInstrumentsStore, import.meta.hot))
}
