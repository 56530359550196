export interface Response<T = unknown> {
  errors: string[]
  success: boolean
  data: T
  total: null | number
}

export interface User {
  id: string
  first_name: string
  last_name: string
  username: string
  photo_url: string
  telegram_identifier: string
  is_admin: null | "1"
  premium_expires_at: null | string
  discount_expires_at: null | string
  premium_period: null | SubscriptionPeriod
  active_purchase_id: null | string
  ban_reason: null | string
}

export interface TradeInstrumentType {
  id: string
  name: string
}

export interface TradeInstrumentComment {
  id: string
  comment: string
  added_at: string
}

export interface TradeInstrument {
  id: string
  shortname: string
  name: string
  isin: string
  secid?: string
  faceunit: keyof typeof Currency
  faceunit_instrument_id: Currency
  lotsize: null | string
  price_by_base_instrument_id: Record<Currency, string>
  trade0provider_id: string
  trade0instrument0type_id: string
  comments: TradeInstrumentComment[]
}

export interface OwnedInstrument {
  instrument_id: string
  quantity: string
  total: string
  avg_price: string
}

export interface WishedInstrument {
  instrument_id: string
  target_percents: string
  actual_price_by_trade_instrument_id: Record<Currency, string>
  actual_quantity: string
  actual_total: string
  decimals: string
  deviation_percents: string
}

export interface OwnedTradeInstrumentInput {
  id: string
  quantity: number
  total: number
  do_not_add_to_added_equity?: 0 | 1
}

export interface DistributionInput {
  id: string | number
  targetPercents: number
}

export interface Portfolio {
  id: string
  user_id: string
  name: string
  emoji: string
  target_percents_by_instrument_type_id: {
    instrument_type_id: string
    target_percents: string
  }[]
  wishes_by_instrument_id: WishedInstrument[]
  instruments: OwnedInstrument[]
  equity_wish_amounts_by_instrument_id: Record<Currency, string>
  equity_addition_threshold: null | InvestmentFrequency
  equity_wish_addition_amounts_by_instrument_id: Record<Currency, string>
  added_equity: string
  total_equity: string
  added_base_instruments_quantities: Record<Currency, string>
  base_instrument_identifier: Currency
}

export enum RebalanceStrategy {
  "normal" = "normal",
  "delta" = "idiotic_5",
  "removal" = "idiotic_removal",
}

export interface RebalanceSuggestion {
  strategy: RebalanceStrategy
  result_base_instrument_equity: number
  suggestions: {
    buy: TradeInstrumentSuggestion[]
    sell: TradeInstrumentSuggestion[]
  }
}

export interface TradeInstrumentSuggestion {
  quantity: string
  trade_instrument_id: string
}

export interface Operation {
  id: string
  quantity: string
  total: string
  type: "buy" | "sell"
  trade_instrument_id: string
  added_at: string
  comment: string | null
}

export interface UploadedFile {
  id: string
  uri: string
}

export interface PromoCode {
  id: string
  code: string
  starts_at: string
  ends_at: string
  usage_limit: string
  activations_amount: string
  comment: string
  is_pcre: "0" | "1"
}

export interface FreeAccessPromoCode extends PromoCode {
  days: string
}

export interface DiscountPromoCode extends PromoCode {
  months: string
  percents: string
}

export interface PaymentPreparation {
  redirect_uri: string
  id: string
}

export type PromoCodeValidation =
  | {
      type: "promo0code"
      effects: {
        free_days: string
      }
    }
  | {
      type: "discount0promo0code"
      effects: {
        discount_percents: string
        discount_duration_months: string
      }
    }

export interface Payment {
  price: string
  original_price: string
  id: string
  added_at: string
  expires_at: string
  paid_at: string
  user_id: string
  user: User
  pan: string
  period: SubscriptionPeriod
  status: PaymentStatus
  code_effects: null | PromoCodeValidation["effects"]
  code: null | string
}

export enum PaymentStatus {
  new = "new",
  paid = "paid",
  verified = "verified",
  failed = "failed",
  cancelled = "cancelled",
  expired = "expired",
}

export enum Currency {
  "USD" = "4159",
  "RUB" = "4161",
  "EUR" = "4162",
}

export enum InvestmentFrequency {
  "monthly" = "month",
  "weekly" = "week",
}

export enum SubscriptionPeriod {
  "month" = "month",
  "year" = "year",
}

export enum InstrumentType {
  Stocks = "1",
  Funds = "2",
  Bonds = "3",
  Currency = "4",
  Crypto = "5",
  Goods = "6",
  RealEstate = "7",
}

export const configKeys = [
  "TEXT_AUTH_SIDEBAR_HEADING",
  "TEXT_AUTH_SIDEBAR_DESC",
  "TEXT_INTRO_WELCOME_HEADING",
  "TEXT_INTRO_WELCOME_DESC",
  "TEXT_INTRO_1_STEP_HEADING",
  "TEXT_INTRO_1_STEP_DESC",
  "INTRO_1_STEP_VIDEO_LINK_DESKTOP",
  "INTRO_1_STEP_VIDEO_LINK_MOBILE",
  "TEXT_INTRO_2_STEP_HEADING",
  "TEXT_INTRO_2_STEP_DESC",
  "INTRO_2_STEP_VIDEO_LINK_DESKTOP",
  "INTRO_2_STEP_VIDEO_LINK_MOBILE",
  "TEXT_INTRO_3_STEP_HEADING",
  "TEXT_INTRO_3_STEP_DESC",
  "INTRO_3_STEP_VIDEO_LINK_DESKTOP",
  "INTRO_3_STEP_VIDEO_LINK_MOBILE",
  "TEXT_FIRST_PORTFOLIO_HEADING",
  "TEXT_FIRST_PORTFOLIO_DESC",
  "ADS_LINK",
  "ADS_AUTH_BANNER_LINK",
  "ADS_PORTFOLIO_BANNER_LINK_DESKTOP",
  "ADS_PORTFOLIO_BANNER_LINK_MOBILE",
  "ADS_AUTH_BANNER_NAME",
  "OFFER_AGREEMENT_LINK",
  "PRIVACY_POLICY_LINK",
  "DISCLAIMER",
  "EMAIL",
  "TELEGRAM_USERNAME",
  "PHONE",
  "ORGANIZATION",
  "SUBSCRIPTION_PRICE_RUB_MONTH",
  "MONTHLY_SUBSCRIPTION_DESCRIPTION",
  "SUBSCRIPTION_PRICE_RUB_YEAR",
  "YEARLY_SUBSCRIPTION_DESCRIPTION",
] as const

export type ConfigKey = (typeof configKeys)[number]

export type Config = Record<ConfigKey, string>

export interface Page {
  id: string
  sort: string
  content: string
  uri: string
  description: string
  title: string
}
