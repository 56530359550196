import { Currency } from "~/models/api"

export function useBaseInstrument() {
  const portfolioStore = usePortfolioStore()
  const id = computed(() => portfolioStore.portfolio?.base_instrument_identifier as Currency)

  const code = computed(() => {
    switch (id.value) {
      case Currency.RUB:
        return "RUB"
      case Currency.USD:
        return "USD"
      case Currency.EUR:
        return "EUR"
    }
  })

  const sign = computed(() => currency.getSign(id.value))

  return {
    id,
    code,
    sign,
  }
}
