<template>
  <v-modal>
    <v-modal-heading class="mb-24">Переименовать портфель</v-modal-heading>

    <modal-shared-portfolio-name />

    <v-modal-footer class="mt-24">
      <v-button
        :disabled="!canSubmit"
        :loading="submitting"
        @click="submit"
      >
        Переименовать
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const modalsStore = useModalsStore()
const portfolioStore = usePortfolioStore()

const name = ref(portfolioStore.portfolio?.name || "")
const emoji = ref(portfolioStore.portfolio?.emoji || "")

provide("name", name)
provide("emoji", emoji)

const canSubmit = computed(() => name.value && emoji.value)
const submitting = ref(false)
async function submit() {
  if (!canSubmit.value) {
    return
  }

  submitting.value = true

  const response = await editPortfolio({
    id: portfolioStore.portfolio?.id || "0",
    name: name.value,
    emoji: emoji.value,
  })

  if (!response.data.success) {
    submitting.value = false
    return
  }

  const route = useRoute()
  await portfolioStore.updatePortfolio(route.params.id as string, { force: true })

  submitting.value = false

  modalsStore.hide()
}
</script>
