import { default as configAkstyUq11FMeta } from "/Users/v/Projects/rebalance/pages/admin/config.vue?macro=true";
import { default as discount_45promo_45codesUQRbqJgqRdMeta } from "/Users/v/Projects/rebalance/pages/admin/discount-promo-codes.vue?macro=true";
import { default as free_45access_45promo_45codesbxsRbJaTIvMeta } from "/Users/v/Projects/rebalance/pages/admin/free-access-promo-codes.vue?macro=true";
import { default as indexfYC27A7LvGMeta } from "/Users/v/Projects/rebalance/pages/admin/index.vue?macro=true";
import { default as indexsQ9wiy90PQMeta } from "/Users/v/Projects/rebalance/pages/admin/pages/index.vue?macro=true";
import { default as _91id_93csCLvvIHSwMeta } from "/Users/v/Projects/rebalance/pages/admin/pages/manage/[id].vue?macro=true";
import { default as paymentsSSgWCnbB1xMeta } from "/Users/v/Projects/rebalance/pages/admin/payments.vue?macro=true";
import { default as texts07wqDEhdUuMeta } from "/Users/v/Projects/rebalance/pages/admin/texts.vue?macro=true";
import { default as usersDnnfaPcbv9Meta } from "/Users/v/Projects/rebalance/pages/admin/users.vue?macro=true";
import { default as authcCI9l7QeCXMeta } from "/Users/v/Projects/rebalance/pages/auth.vue?macro=true";
import { default as banned2QA3IJq0GZMeta } from "/Users/v/Projects/rebalance/pages/banned.vue?macro=true";
import { default as first_45portfoliow7Ex0ZWDlrMeta } from "/Users/v/Projects/rebalance/pages/first-portfolio.vue?macro=true";
import { default as indexDsG7SC2chzMeta } from "/Users/v/Projects/rebalance/pages/index.vue?macro=true";
import { default as _1BVKrO2jWDgMeta } from "/Users/v/Projects/rebalance/pages/intro/1.vue?macro=true";
import { default as _2eEPWuNlQVaMeta } from "/Users/v/Projects/rebalance/pages/intro/2.vue?macro=true";
import { default as _3QP2CqAZF6fMeta } from "/Users/v/Projects/rebalance/pages/intro/3.vue?macro=true";
import { default as indexe97dhQX5iTMeta } from "/Users/v/Projects/rebalance/pages/intro/index.vue?macro=true";
import { default as fail0nk74Lve3SMeta } from "/Users/v/Projects/rebalance/pages/payment/fail.vue?macro=true";
import { default as successi1VmjqTAnBMeta } from "/Users/v/Projects/rebalance/pages/payment/success.vue?macro=true";
import { default as activeMkkpYe58NhMeta } from "/Users/v/Projects/rebalance/pages/plan/active.vue?macro=true";
import { default as expiredZD7I6xDbPUMeta } from "/Users/v/Projects/rebalance/pages/plan/expired.vue?macro=true";
import { default as index4IRAnFjkhmMeta } from "/Users/v/Projects/rebalance/pages/plan/index.vue?macro=true";
import { default as indexqJztIbJaY2Meta } from "/Users/v/Projects/rebalance/pages/portfolio/[id]/index.vue?macro=true";
import { default as _91instrumentId_937AuvLN7V2lMeta } from "/Users/v/Projects/rebalance/pages/portfolio/[id]/instrument/[instrumentId].vue?macro=true";
import { default as _91_46_46_46slug_93czZKOWBDqeMeta } from "/Users/v/Projects/rebalance/pages/text/[...slug].vue?macro=true";
export default [
  {
    name: configAkstyUq11FMeta?.name ?? "admin-config",
    path: configAkstyUq11FMeta?.path ?? "/admin/config",
    meta: configAkstyUq11FMeta || {},
    alias: configAkstyUq11FMeta?.alias || [],
    redirect: configAkstyUq11FMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/admin/config.vue").then(m => m.default || m)
  },
  {
    name: discount_45promo_45codesUQRbqJgqRdMeta?.name ?? "admin-discount-promo-codes",
    path: discount_45promo_45codesUQRbqJgqRdMeta?.path ?? "/admin/discount-promo-codes",
    meta: discount_45promo_45codesUQRbqJgqRdMeta || {},
    alias: discount_45promo_45codesUQRbqJgqRdMeta?.alias || [],
    redirect: discount_45promo_45codesUQRbqJgqRdMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/admin/discount-promo-codes.vue").then(m => m.default || m)
  },
  {
    name: free_45access_45promo_45codesbxsRbJaTIvMeta?.name ?? "admin-free-access-promo-codes",
    path: free_45access_45promo_45codesbxsRbJaTIvMeta?.path ?? "/admin/free-access-promo-codes",
    meta: free_45access_45promo_45codesbxsRbJaTIvMeta || {},
    alias: free_45access_45promo_45codesbxsRbJaTIvMeta?.alias || [],
    redirect: free_45access_45promo_45codesbxsRbJaTIvMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/admin/free-access-promo-codes.vue").then(m => m.default || m)
  },
  {
    name: indexfYC27A7LvGMeta?.name ?? "admin",
    path: indexfYC27A7LvGMeta?.path ?? "/admin",
    meta: indexfYC27A7LvGMeta || {},
    alias: indexfYC27A7LvGMeta?.alias || [],
    redirect: indexfYC27A7LvGMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexsQ9wiy90PQMeta?.name ?? "admin-pages",
    path: indexsQ9wiy90PQMeta?.path ?? "/admin/pages",
    meta: indexsQ9wiy90PQMeta || {},
    alias: indexsQ9wiy90PQMeta?.alias || [],
    redirect: indexsQ9wiy90PQMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93csCLvvIHSwMeta?.name ?? "admin-pages-manage-id",
    path: _91id_93csCLvvIHSwMeta?.path ?? "/admin/pages/manage/:id()",
    meta: _91id_93csCLvvIHSwMeta || {},
    alias: _91id_93csCLvvIHSwMeta?.alias || [],
    redirect: _91id_93csCLvvIHSwMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/admin/pages/manage/[id].vue").then(m => m.default || m)
  },
  {
    name: paymentsSSgWCnbB1xMeta?.name ?? "admin-payments",
    path: paymentsSSgWCnbB1xMeta?.path ?? "/admin/payments",
    meta: paymentsSSgWCnbB1xMeta || {},
    alias: paymentsSSgWCnbB1xMeta?.alias || [],
    redirect: paymentsSSgWCnbB1xMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/admin/payments.vue").then(m => m.default || m)
  },
  {
    name: texts07wqDEhdUuMeta?.name ?? "admin-texts",
    path: texts07wqDEhdUuMeta?.path ?? "/admin/texts",
    meta: texts07wqDEhdUuMeta || {},
    alias: texts07wqDEhdUuMeta?.alias || [],
    redirect: texts07wqDEhdUuMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/admin/texts.vue").then(m => m.default || m)
  },
  {
    name: usersDnnfaPcbv9Meta?.name ?? "admin-users",
    path: usersDnnfaPcbv9Meta?.path ?? "/admin/users",
    meta: usersDnnfaPcbv9Meta || {},
    alias: usersDnnfaPcbv9Meta?.alias || [],
    redirect: usersDnnfaPcbv9Meta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: authcCI9l7QeCXMeta?.name ?? "auth",
    path: authcCI9l7QeCXMeta?.path ?? "/auth",
    meta: authcCI9l7QeCXMeta || {},
    alias: authcCI9l7QeCXMeta?.alias || [],
    redirect: authcCI9l7QeCXMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: banned2QA3IJq0GZMeta?.name ?? "banned",
    path: banned2QA3IJq0GZMeta?.path ?? "/banned",
    meta: banned2QA3IJq0GZMeta || {},
    alias: banned2QA3IJq0GZMeta?.alias || [],
    redirect: banned2QA3IJq0GZMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/banned.vue").then(m => m.default || m)
  },
  {
    name: first_45portfoliow7Ex0ZWDlrMeta?.name ?? "first-portfolio",
    path: first_45portfoliow7Ex0ZWDlrMeta?.path ?? "/first-portfolio",
    meta: first_45portfoliow7Ex0ZWDlrMeta || {},
    alias: first_45portfoliow7Ex0ZWDlrMeta?.alias || [],
    redirect: first_45portfoliow7Ex0ZWDlrMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/first-portfolio.vue").then(m => m.default || m)
  },
  {
    name: indexDsG7SC2chzMeta?.name ?? "index",
    path: indexDsG7SC2chzMeta?.path ?? "/",
    meta: indexDsG7SC2chzMeta || {},
    alias: indexDsG7SC2chzMeta?.alias || [],
    redirect: indexDsG7SC2chzMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _1BVKrO2jWDgMeta?.name ?? "intro-1",
    path: _1BVKrO2jWDgMeta?.path ?? "/intro/1",
    meta: _1BVKrO2jWDgMeta || {},
    alias: _1BVKrO2jWDgMeta?.alias || [],
    redirect: _1BVKrO2jWDgMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/intro/1.vue").then(m => m.default || m)
  },
  {
    name: _2eEPWuNlQVaMeta?.name ?? "intro-2",
    path: _2eEPWuNlQVaMeta?.path ?? "/intro/2",
    meta: _2eEPWuNlQVaMeta || {},
    alias: _2eEPWuNlQVaMeta?.alias || [],
    redirect: _2eEPWuNlQVaMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/intro/2.vue").then(m => m.default || m)
  },
  {
    name: _3QP2CqAZF6fMeta?.name ?? "intro-3",
    path: _3QP2CqAZF6fMeta?.path ?? "/intro/3",
    meta: _3QP2CqAZF6fMeta || {},
    alias: _3QP2CqAZF6fMeta?.alias || [],
    redirect: _3QP2CqAZF6fMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/intro/3.vue").then(m => m.default || m)
  },
  {
    name: indexe97dhQX5iTMeta?.name ?? "intro",
    path: indexe97dhQX5iTMeta?.path ?? "/intro",
    meta: indexe97dhQX5iTMeta || {},
    alias: indexe97dhQX5iTMeta?.alias || [],
    redirect: indexe97dhQX5iTMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/intro/index.vue").then(m => m.default || m)
  },
  {
    name: fail0nk74Lve3SMeta?.name ?? "payment-fail",
    path: fail0nk74Lve3SMeta?.path ?? "/payment/fail",
    meta: fail0nk74Lve3SMeta || {},
    alias: fail0nk74Lve3SMeta?.alias || [],
    redirect: fail0nk74Lve3SMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/payment/fail.vue").then(m => m.default || m)
  },
  {
    name: successi1VmjqTAnBMeta?.name ?? "payment-success",
    path: successi1VmjqTAnBMeta?.path ?? "/payment/success",
    meta: successi1VmjqTAnBMeta || {},
    alias: successi1VmjqTAnBMeta?.alias || [],
    redirect: successi1VmjqTAnBMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: activeMkkpYe58NhMeta?.name ?? "plan-active",
    path: activeMkkpYe58NhMeta?.path ?? "/plan/active",
    meta: activeMkkpYe58NhMeta || {},
    alias: activeMkkpYe58NhMeta?.alias || [],
    redirect: activeMkkpYe58NhMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/plan/active.vue").then(m => m.default || m)
  },
  {
    name: expiredZD7I6xDbPUMeta?.name ?? "plan-expired",
    path: expiredZD7I6xDbPUMeta?.path ?? "/plan/expired",
    meta: expiredZD7I6xDbPUMeta || {},
    alias: expiredZD7I6xDbPUMeta?.alias || [],
    redirect: expiredZD7I6xDbPUMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/plan/expired.vue").then(m => m.default || m)
  },
  {
    name: index4IRAnFjkhmMeta?.name ?? "plan",
    path: index4IRAnFjkhmMeta?.path ?? "/plan",
    meta: index4IRAnFjkhmMeta || {},
    alias: index4IRAnFjkhmMeta?.alias || [],
    redirect: index4IRAnFjkhmMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/plan/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJztIbJaY2Meta?.name ?? "portfolio-id",
    path: indexqJztIbJaY2Meta?.path ?? "/portfolio/:id()",
    meta: indexqJztIbJaY2Meta || {},
    alias: indexqJztIbJaY2Meta?.alias || [],
    redirect: indexqJztIbJaY2Meta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/portfolio/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91instrumentId_937AuvLN7V2lMeta?.name ?? "portfolio-id-instrument-instrumentId",
    path: _91instrumentId_937AuvLN7V2lMeta?.path ?? "/portfolio/:id()/instrument/:instrumentId()",
    meta: _91instrumentId_937AuvLN7V2lMeta || {},
    alias: _91instrumentId_937AuvLN7V2lMeta?.alias || [],
    redirect: _91instrumentId_937AuvLN7V2lMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/portfolio/[id]/instrument/[instrumentId].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93czZKOWBDqeMeta?.name ?? "text-slug",
    path: _91_46_46_46slug_93czZKOWBDqeMeta?.path ?? "/text/:slug(.*)*",
    meta: _91_46_46_46slug_93czZKOWBDqeMeta || {},
    alias: _91_46_46_46slug_93czZKOWBDqeMeta?.alias || [],
    redirect: _91_46_46_46slug_93czZKOWBDqeMeta?.redirect,
    component: () => import("/Users/v/Projects/rebalance/pages/text/[...slug].vue").then(m => m.default || m)
  }
]