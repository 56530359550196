<template>
  <div class="flex h-[38px] w-[38px] shrink-0 basis-[38px] items-center justify-center overflow-hidden rounded-12 bg-f0f1f4">
    <img
      v-if="src || path"
      :src="src || getImage(path as string)"
      class="h-full w-full object-cover"
    />
    <Icon
      v-else-if="name"
      class="!flex items-center justify-center"
      size="22px"
      :name="name"
    />
  </div>
</template>

<script setup lang="ts">
defineProps<{
  path?: string
  name?: string
  src?: string
}>()

const { getImage } = useAssets()
</script>
