import revive_payload_client_dnOu3p3RVJ from "/Users/v/Projects/rebalance/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_axios@1.6.8_encod_avt3cytkmn3z3f5eu634fxafhe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GAIqo9qxFS from "/Users/v/Projects/rebalance/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_axios@1.6.8_encod_avt3cytkmn3z3f5eu634fxafhe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_u22IUR40W2 from "/Users/v/Projects/rebalance/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_axios@1.6.8_encod_avt3cytkmn3z3f5eu634fxafhe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_eyk2KWWzUV from "/Users/v/Projects/rebalance/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_axios@1.6.8_encod_avt3cytkmn3z3f5eu634fxafhe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_gXpJ4jM1yC from "/Users/v/Projects/rebalance/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_axios@1.6.8_encod_avt3cytkmn3z3f5eu634fxafhe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_eCJApKtql8 from "/Users/v/Projects/rebalance/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.16.3_typescript@5.4.5_vue@3.4.24_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/v/Projects/rebalance/.nuxt/components.plugin.mjs";
import prefetch_client_6CKww1NYwA from "/Users/v/Projects/rebalance/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_axios@1.6.8_encod_avt3cytkmn3z3f5eu634fxafhe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ztB46U1doJ from "/Users/v/Projects/rebalance/node_modules/.pnpm/yandex-metrika-module-nuxt3@1.5.3_rollup@4.16.3/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import chunk_reload_client_nxWHnSIJJF from "/Users/v/Projects/rebalance/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_axios@1.6.8_encod_avt3cytkmn3z3f5eu634fxafhe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import config_Lw1zr0Vi5r from "/Users/v/Projects/rebalance/plugins/config.ts";
import emitter_eaMgM0oZTb from "/Users/v/Projects/rebalance/plugins/emitter.ts";
import sentry_3AyO8nEfhE from "/Users/v/Projects/rebalance/plugins/sentry.ts";
import user_0y9HlFsUwP from "/Users/v/Projects/rebalance/plugins/user.ts";
export default [
  revive_payload_client_dnOu3p3RVJ,
  unhead_GAIqo9qxFS,
  router_u22IUR40W2,
  payload_client_eyk2KWWzUV,
  check_outdated_build_client_gXpJ4jM1yC,
  plugin_vue3_eCJApKtql8,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6CKww1NYwA,
  plugin_ztB46U1doJ,
  chunk_reload_client_nxWHnSIJJF,
  config_Lw1zr0Vi5r,
  emitter_eaMgM0oZTb,
  sentry_3AyO8nEfhE,
  user_0y9HlFsUwP
]