import validate from "/Users/v/Projects/rebalance/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_axios@1.6.8_encod_avt3cytkmn3z3f5eu634fxafhe/node_modules/nuxt/dist/pages/runtime/validate.js";
import admin_45global from "/Users/v/Projects/rebalance/middleware/admin.global.ts";
import auth_45global from "/Users/v/Projects/rebalance/middleware/auth.global.ts";
import ban_45global from "/Users/v/Projects/rebalance/middleware/ban.global.ts";
import manifest_45route_45rule from "/Users/v/Projects/rebalance/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_axios@1.6.8_encod_avt3cytkmn3z3f5eu634fxafhe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  admin_45global,
  auth_45global,
  ban_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  premium: () => import("/Users/v/Projects/rebalance/middleware/premium.ts")
}