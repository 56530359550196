<template>
  <v-modal>
    <v-modal-heading class="mb-32">Детализация инвест-портфеля</v-modal-heading>

    <div class="mb-32 flex items-center gap-24">
      <v-doughnut-chart
        v-if="showEquityByCurrencyChart"
        class="h-[160px] w-[160px]"
        :cutout="57"
        :colors="colors"
        :data="charts.equityByCurrency.map((item) => item.share)"
      />
      <div>
        <p class="mb-20 text-18 font-bold">Баланс инвест-портфеля</p>
        <div class="flex flex-wrap gap-x-20 gap-y-15">
          <div
            v-for="(item, idx) of charts.equityByCurrency"
            :key="item.id"
            class="flex items-start gap-10"
          >
            <div
              v-if="showEquityByCurrencyChart"
              class="mt-4 h-12 w-12 basis-12 rounded-full"
              :style="{ background: colors[idx] }"
            ></div>
            <div>
              <p class="mb-5 text-14 font-medium text-84849b">{{ item.name }}</p>
              <p class="text-18 font-bold">
                {{ formatMoney(item.quantity) }} {{ currency.getSign(item.id) }} ({{ formatPercentage(item.share * 100) }})
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center gap-24">
      <v-doughnut-chart
        v-if="showInvestedAmountByCurrencyChart"
        class="h-[160px] w-[160px]"
        :cutout="57"
        :colors="colors"
        :data="charts.investedAmountByCurrency.map((item) => item.share)"
      />
      <div>
        <p class="mb-20 text-18 font-bold">Вложено средств</p>
        <div class="flex flex-wrap gap-x-20 gap-y-15">
          <div
            v-for="(item, idx) of charts.investedAmountByCurrency"
            :key="item.id"
            class="flex items-start gap-10"
          >
            <div
              v-if="showInvestedAmountByCurrencyChart"
              class="mt-4 h-12 w-12 basis-12 rounded-full"
              :style="{ background: colors[idx] }"
            ></div>
            <div>
              <p class="mb-5 text-14 font-medium text-84849b">{{ item.name }}</p>
              <p class="text-18 font-bold">
                {{ formatMoney(item.quantity) }} {{ currency.getSign(item.id) }} ({{ formatPercentage(item.share * 100) }})
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-modal>
</template>

<script setup lang="ts">
const colors = ["#c4c8dd", "#95ffa5", "#629eff", "#ffb968", "#fff068"]

const { charts } = storeToRefs(usePortfolioStore())

const showEquityByCurrencyChart = computed(() => charts.value.equityByCurrency.some((item) => item.share))
const showInvestedAmountByCurrencyChart = computed(() => charts.value.investedAmountByCurrency.some((item) => item.share))
</script>
