<template>
  <v-number-input
    :key="id"
    :suffix="` ${sign}`"
    :minimum-fraction-digits="2"
    :maximum-fraction-digits="2"
  />
</template>

<script setup lang="ts">
import { Currency } from "~/models/api"

const props = defineProps<{
  baseInstrumentId?: Currency
}>()

const baseInstrument = useBaseInstrument()

const id = computed(() => {
  return props.baseInstrumentId || baseInstrument.id.value
})

const sign = computed(() => {
  if (props.baseInstrumentId) {
    return currency.getSign(props.baseInstrumentId)
  }

  return baseInstrument.sign.value
})
</script>
