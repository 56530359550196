<template>
  <div class="mb-24 flex items-end justify-between md:flex-col md:items-start">
    <p class="text-424250">Задайте общее распределение по типам активов в процентах:</p>
    <p
      v-if="leftToDistributeByTypes"
      class="mt-6 text-14 font-medium text-84849b"
    >
      <template v-if="leftToDistributeByTypes < 0">
        Нужно убрать лишние
        <span class="text-f83d3d">{{ formatPercentage(leftToDistributeByTypes) }}%</span>
      </template>
      <template v-else>
        Нужно распределить еще
        <span class="text-f8963d">+{{ formatPercentage(leftToDistributeByTypes) }}%</span>
      </template>
    </p>
  </div>
  <div class="mb-24 grid grid-cols-3 gap-16 md:gap-10">
    <v-number-input
      v-for="instrumentTypeId of usedInstrumentsTypesIds"
      :key="instrumentTypeId"
      v-model="distributionByTypes[instrumentTypeId]"
      :placeholder="instrumentsStore.types[instrumentTypeId]"
      stack-placeholder
      :maximum-fraction-digits="2"
      suffix="%"
    />
  </div>

  <p class="mb-24 text-424250">Задайте долю каждого типа актива в портфеле:</p>
  <template
    v-for="instrumentTypeId of usedInstrumentsTypesIds"
    :key="instrumentTypeId"
  >
    <div class="mb-16 flex justify-between">
      <h2 class="text-18 font-bold">{{ instrumentsStore.types[instrumentTypeId] }}</h2>
      <p
        v-if="getLeftToDistributeByType(instrumentTypeId)"
        class="text-14 font-medium text-84849b"
      >
        <template v-if="getLeftToDistributeByType(instrumentTypeId) < 0">
          Нужно убрать лишние
          <span class="text-f83d3d">{{ formatPercentage(getLeftToDistributeByType(instrumentTypeId)) }}%</span>
        </template>
        <template v-else>
          Нужно распределить еще
          <span class="text-f8963d">+{{ formatPercentage(getLeftToDistributeByType(instrumentTypeId)) }}%</span>
        </template>
      </p>
    </div>

    <div class="mb-24 flex flex-col gap-16">
      <div
        v-for="instrument of getInstrumentByTypeId(instrumentTypeId)"
        :key="instrument.id"
        class="flex gap-16 md:gap-10"
      >
        <v-input
          class="w-0 flex-1"
          :model-value="instrument.name"
          placeholder="Актив"
          disabled
        >
          <template #icon>
            <v-asset-icon
              :type-id="instrument.trade0instrument0type_id"
              light
            />
          </template>
        </v-input>
        <v-number-input
          v-model="distributionByInstruments[instrument.id]"
          class="w-0 flex-1 md:flex-initial md:basis-[140px]"
          placeholder="Целевая доля"
          :maximum-fraction-digits="2"
          suffix="%"
        />
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
defineProps<{
  valid?: boolean
}>()

const emit = defineEmits<{
  "update:valid": [value: boolean]
}>()

const instrumentsStore = useInstrumentsStore()

const ownedInstruments = inject("ownedInstruments", ref([])) as Ref<string[]>
const desiredInstruments = inject("desiredInstruments") as Ref<string[]>
const distributionByTypes = inject("distributionByTypes") as Ref<Record<string, number>>
const distributionByInstruments = inject("distributionByInstruments") as Ref<Record<string, number>>

const usedInstrumentsIds = Array.from(new Set([...ownedInstruments.value, ...desiredInstruments.value])).filter(Boolean)
const usedInstruments = await Promise.all(usedInstrumentsIds.map((id) => instrumentsStore.getInstrument(id)))

const usedInstrumentsTypesIds = Array.from(new Set(usedInstruments.map((instr) => instr.trade0instrument0type_id)))

function getInstrumentByTypeId(typeId: string) {
  return usedInstruments.filter((instr) => instr.trade0instrument0type_id === typeId)
}

const leftToDistributeByTypes = computed(() => {
  return 100 - Object.values(distributionByTypes.value).reduce((sum, a) => sum + a * 100, 0) / 100
})

const canSubmit = computed(() => {
  return (
    usedInstrumentsIds.some((id) => distributionByInstruments.value[id] !== undefined) &&
    usedInstrumentsTypesIds.some((id) => distributionByTypes.value[id] !== undefined) &&
    !leftToDistributeByTypes.value &&
    usedInstrumentsTypesIds.every((typeId) => !getLeftToDistributeByType(typeId))
  )
})

emit("update:valid", canSubmit.value)
watch(canSubmit, () => {
  emit("update:valid", canSubmit.value)
})

function getLeftToDistributeByType(typeId: string) {
  const target = distributionByTypes.value[typeId] || 0
  const sum =
    usedInstruments
      .filter((i) => i.trade0instrument0type_id === typeId)
      .reduce((sum, i) => sum + (distributionByInstruments.value[i.id] || 0) * 100, 0) / 100

  return target - sum
}

function formatPercentage(number: number) {
  return number.toLocaleString("ru-RU", { maximumFractionDigits: 2 })
}
</script>
