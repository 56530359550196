<template>
  <button
    class="flex w-full items-center gap-8 break-words px-12 py-4 text-left leading-18 transition-all hover:bg-f0f1f4/40 md:px-0"
    :class="{
      'md:justify-center md:rounded-10 md:border md:border-c7c6cd md:p-6 md:text-center': !hasIcon,
    }"
  >
    <slot name="icon">
      <v-field-icon
        v-if="iconName || iconPath"
        :path="iconPath"
        :name="iconName"
      />
    </slot>
    <span class="text-black">
      <slot></slot>

      <span
        v-if="secondaryLabel"
        class="before:cont text-14 font-normal text-84849b before:content-['_']"
      >
        {{ secondaryLabel }}
      </span>
    </span>
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{
  iconName?: string
  iconPath?: string
  secondaryLabel?: string
}>()

const slots = useSlots()

const hasIcon = computed(() => props.iconName || props.iconPath || slots.icon)
</script>
