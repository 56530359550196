<template>
  <div
    class="w-full max-w-[1500px] px-20 md:max-w-[100vw] md:px-16"
    :class="{
      [bgClass]: props.pageBg,
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  pageBg?: boolean
}>()

const bgClass = inject("pageBgClass") as Ref<string>
</script>
