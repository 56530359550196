<template>
  <component
    :is="props.tag"
    class="flex items-center gap-15 text-26 font-bold md:gap-6 md:text-18"
    :class="{
      'text-232335': props.color === 'default',
      'text-white': props.color === 'white',
    }"
  >
    <img
      v-if="props.color === 'default'"
      src="@/assets/images/logo-colored.svg"
      class="md:h-24 md:w-24"
    />
    <img
      v-else-if="props.color === 'white'"
      src="@/assets/images/logo-white.svg"
      class="md:h-24 md:w-24"
    />
    AW Finance
  </component>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    color?: "default" | "white"
    tag?: string
  }>(),
  {
    color: "default",
    tag: "span",
  }
)
</script>
