export function getRussianNounForm(number: number, oneForm: string, twoForm: string, fiveForm: string) {
  const remainder10 = Math.abs(number) % 10
  const remainder100 = Math.abs(number) % 100

  if (remainder100 >= 11 && remainder100 <= 19) {
    return fiveForm
  }

  if (remainder10 >= 2 && remainder10 <= 4) {
    return twoForm
  }

  if (remainder10 === 1) {
    return oneForm
  }

  return fiveForm
}
