<template>
  <div class="spinner"></div>
</template>

<script setup lang="ts">
// for tailwind compatibility colors are set with outer classes, e.g. "border-white/30 border-l-white"

const props = withDefaults(
  defineProps<{
    size?: string
    thickness?: string
  }>(),
  {
    size: "100px",
    thickness: "10px",
  }
)
</script>

<style lang="scss" scoped>
.spinner {
  width: v-bind("props.size");
  height: v-bind("props.size");
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  border-width: v-bind("props.thickness");
  border-left-width: v-bind("props.thickness");
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
