<template>
  <v-modal>
    <v-asset-snippet
      :id="options.id"
      size="lg"
      class="mb-24"
    />

    <div class="flex flex-col gap-24">
      <div
        v-for="comment in instrument.comments"
        :key="comment.id"
        class="rounded-12 bg-f9f9f9 px-12 py-16"
      >
        <p class="mb-10 whitespace-pre-line break-words font-medium">{{ comment.comment }}</p>
        <div class="flex items-end gap-16">
          <p
            class="text-13 font-medium text-84849b"
            :title="new Date(+comment.added_at * 1000).toLocaleString()"
          >
            {{ moment(comment.added_at, "X").fromNow() }}
          </p>

          <button
            class="ml-auto text-f83d3d"
            @click="
              modals.show('delete-instrument-comment', {
                instrumentId: options.id,
                commentId: comment.id,
              })
            "
          >
            <Icon
              name="tabler:trash"
              size="20px"
            />
          </button>
        </div>
      </div>
    </div>

    <v-modal-footer class="mt-24">
      <v-button @click="modals.show('add-asset-comment', { id: options.id })">Добавить комментарий</v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
// TODO come up with something lighter
import moment from "moment"
import "moment/dist/locale/ru"

moment.locale("ru")

const options = useModalOptions<"instrument-comments">()
const modals = useModalsStore()

const instrumentsStore = useInstrumentsStore()
const instrument = await instrumentsStore.getInstrument(options.value.id)
</script>
