<template>
  <v-modal>
    <v-modal-heading class="mb-24">Добавление комментария</v-modal-heading>
    <p class="mb-16">Введите текст комментария:</p>
    <v-textarea
      v-model="comment"
      placeholder="Комментарий"
      :maxlength="150"
      :rows="4"
    />
    <v-modal-footer class="mt-24">
      <v-button
        :disabled="!comment.length"
        :loading="submitting"
        @click="submit"
      >
        Добавить комментарий
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const options = useModalOptions<"add-asset-comment">()

const modals = useModalsStore()

const comment = ref("")

const submitting = ref(false)
async function submit() {
  submitting.value = true

  const response = await addInstrumentComment({ instrumentId: options.value.id, comment: comment.value })

  if (!response.data.success) {
    submitting.value = false
    return
  }

  const instrumentsStore = useInstrumentsStore()
  await instrumentsStore.reloadInstrument(options.value.id)

  const portfolioStore = usePortfolioStore()
  await portfolioStore.reloadPortfolio()

  submitting.value = false

  modals.hide()
}
</script>
