<template>
  <v-modal>
    <v-modal-heading class="mb-24">{{ instrument.id === baseInstrument.id.value ? "Добавить / вывести" : "Купить / продать" }}</v-modal-heading>
    <p class="mb-24">Выберите желаемое действие с активом:</p>

    <v-radio-buttons
      v-model="action"
      :options="actions"
    />

    <v-asset-snippet
      :id="instrument.id"
      class="my-16"
    />

    <div
      v-if="action === 'sell'"
      class="my-16 flex flex-wrap items-end gap-16"
    >
      <v-button
        variant="outlined-with-bg"
        @click="sellAllByMarketPrice"
      >
        {{ sellAllByMarketPriceLabel }}
      </v-button>
      <v-button
        v-if="instrument.id !== baseInstrument.id.value"
        variant="outlined-with-bg"
        @click="sellAllByAveragePrice"
      >
        {{ sellAllByAveragePriceLabel }}
      </v-button>
    </div>

    <div class="grid grid-cols-2 gap-16 md:gap-10">
      <v-number-input
        v-if="instrument.id !== baseInstrument.id.value"
        v-model="quantity"
        placeholder="Количество штук"
        :maximum-fraction-digits="10"
        required
        stack-placeholder
      />

      <v-base-instrument-number-input
        v-model="price"
        placeholder="Сумма"
        required
        stack-placeholder
      />
    </div>

    <v-modal-footer class="mt-24">
      <v-button
        :loading="isPending"
        :disabled="isDisabled"
        @click="submit"
      >
        {{ actionLabel }}
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const baseInstrument = useBaseInstrument()

const instrumentsStore = useInstrumentsStore()
const options = useModalOptions<"operate-asset">()

const portfolioStore = usePortfolioStore()
const instrumentInPortfolio = computed(() => portfolioStore.portfolio?.instruments.find((instr) => instr.instrument_id === options.value.id))
const actualQuantity = computed(() => {
  if (instrumentInPortfolio.value) {
    return Number(instrumentInPortfolio.value.quantity)
  }

  return 0
})

const instrument = await instrumentsStore.getInstrument(options.value.id)

const actions = computed(() => {
  const list: { value: "sell" | "buy"; label: string }[] = [
    {
      value: "buy",
      label: instrument.id === baseInstrument.id.value ? "Добавить" : "Купить",
    },
  ]

  if (actualQuantity.value > 0) {
    list.push({
      value: "sell",
      label: instrument.id === baseInstrument.id.value ? "Вывести" : "Продать",
    })
  }

  return list
})
const action = ref(options.value.operation)
const actionOption = computed(() => actions.value.find((a) => a.value === action.value))
if (!actionOption.value) {
  action.value = actions.value[0].value
}
const actionLabel = computed(() => actionOption.value?.label)

const quantity = ref<number>()
const price = ref<number>()
watchEffect(() => {
  if (instrument.id === baseInstrument.id.value) {
    quantity.value = price.value
  }
})

const sellAllByMarketPriceLabel = computed(() => (instrument.id === baseInstrument.id.value ? "Вывести всё" : "Продать всё по рыночной цене"))
function sellAllByMarketPrice() {
  quantity.value = actualQuantity.value
  price.value = +instrument.price_by_base_instrument_id[baseInstrument.id.value] * actualQuantity.value
}

const sellAllByAveragePriceLabel = "Продать всё по средней цене"
function sellAllByAveragePrice() {
  quantity.value = actualQuantity.value
  price.value = (Number(instrumentInPortfolio.value?.avg_price) || 0) * actualQuantity.value
}

const isDisabled = computed(() => {
  return !quantity.value || !price.value
})
const isPending = ref(false)

async function submit() {
  isPending.value = true

  try {
    const portfolioStore = usePortfolioStore()

    const response = await editPortfolio({
      id: portfolioStore.portfolio?.id,
      [action.value]: [
        {
          id: instrument.id,
          quantity: quantity.value,
          total: price.value,
        },
      ],
    })

    if (response.data.success) {
      await portfolioStore.updatePortfolio()

      const modalsStore = useModalsStore()
      modalsStore.hide()
    }
  } finally {
    isPending.value = false
  }
}
</script>
