import { groupBy } from "lodash-es"
import { Currency, InvestmentFrequency, OwnedInstrument, Portfolio, TradeInstrument, WishedInstrument } from "~/models/api"

interface PortfolioInstrument extends TradeInstrument {
  share: number
  targetPercents?: number
  deviationPercents?: number
  price: number
  totalPrice: number
  initialPrice: number
  quantity: number
}

interface PortfolioInstrumentType {
  share: number
  targetPercents?: number
  totalPrice: number
  typeId: string
  typeName: string
  instruments: PortfolioInstrument[]
}

interface PortfolioDetails {
  progress: number
  totalPrice: number
  targetPrice: number
  periodTotal: number
  periodTarget: number
  period?: InvestmentFrequency
  types: PortfolioInstrumentType[]
}

interface Charts {
  investedAmountByCurrency: CurrencyChartItem[]
  equityByCurrency: CurrencyChartItem[]
}

interface CurrencyChartItem {
  id: Currency
  name: string
  quantity: number
  total: number
  share: number
}

export const usePortfolioStore = defineStore("portfolio", () => {
  const userId = ref<null | string>(null)

  const portfolios = ref<Portfolio[]>([])
  const portfolio = ref<Portfolio>()
  const loadedBaseInstrumentId = ref(portfolio.value?.base_instrument_identifier as Currency)
  const loadedBaseInstrumentSign = computed(() => currency.getSign(loadedBaseInstrumentId.value))
  const hasPlan = computed(() => portfolio.value?.target_percents_by_instrument_type_id?.length || portfolio.value?.wishes_by_instrument_id?.length)
  const portfolioDetails = ref<PortfolioDetails>({
    progress: 0,
    totalPrice: 0,
    targetPrice: 0,
    periodTarget: 0,
    periodTotal: 0,
    types: [],
  })
  const charts = ref<Charts>({
    investedAmountByCurrency: [],
    equityByCurrency: [],
  })
  const isEmpty = computed(() => !portfolio.value?.instruments.length)

  const instrumentsStore = useInstrumentsStore()

  async function updatePortfolios() {
    const response = await findPortfolios({ userId: userId.value || undefined })
    portfolios.value = response.data.data || []
  }

  async function updateBaseInstrument(baseInstrumentId: Currency) {
    await editPortfolio({
      id: portfolio.value?.id,
      baseInstrumentId,
    })

    return await reloadPortfolio()
  }

  async function reloadPortfolio() {
    if (!portfolio.value?.id) {
      return
    }

    await updatePortfolio(portfolio.value.id, { force: true })
  }

  async function trySetCorrectUserId({ portfolioId }: { portfolioId: string }) {
    const user = useUserStore()
    if (!user.isAdmin) {
      return null
    }

    const response = await findPortfolios({ id: portfolioId })
    const portfolio = response.data.data?.[0]
    if (portfolio) {
      userId.value = portfolio.user_id
      return portfolio.user_id
    }

    return null
  }

  async function updatePortfolio(id?: string, { force }: { force?: boolean } = {}) {
    if (portfolio.value?.id === id && !force) {
      return
    }

    await updatePortfolios()

    const targetId = id || (useRoute().params.id as string)
    portfolio.value = portfolios.value.find((p) => p.id === targetId)

    if (!portfolio.value) {
      const userId = await trySetCorrectUserId({ portfolioId: targetId })
      if (userId) {
        return updatePortfolio(targetId)
      }
    }

    await loadBaseInstruments()

    await calculatePortfolioDetails()

    await calculateCharts()
  }

  async function loadBaseInstruments() {
    await Promise.all(Object.values(Currency).map((id) => instrumentsStore.loadInstrument(id)))
  }

  async function calculateCurrencyChart(data: Record<Currency, number | string>): Promise<CurrencyChartItem[]> {
    const items: CurrencyChartItem[] = Object.entries(data)
      .map(([id, quantity]) => ({
        id: id as Currency,
        name: currency.getName(id),
        quantity: +quantity,
        total: 0,
        share: 0,
      }))
      .sort((a, b) => currency.getSortingPosition(a.id) - currency.getSortingPosition(b.id))

    let totalPrice = 0
    for (const item of items) {
      const instrument = await instrumentsStore.getInstrument(item.id)
      item.total = +instrument.price_by_base_instrument_id[portfolio.value?.base_instrument_identifier as Currency] * item.quantity
      totalPrice += Math.max(item.total, 0)
    }

    for (const item of items) {
      item.share = Math.max(item.total / totalPrice || 0, 0)
    }

    return items
  }

  async function calculateEquityChartData(): Promise<Record<Currency, number>> {
    const data = Object.fromEntries(Object.values(Currency).map((id) => [id, 0])) as Record<Currency, number>

    if (!portfolio.value) {
      return data
    }

    for (const ownedInstrument of portfolio.value.instruments) {
      const instrument = await instrumentsStore.getInstrument(ownedInstrument.instrument_id)
      const price = Number(instrument.price_by_base_instrument_id[instrument.faceunit_instrument_id])
      if (!price) {
        continue
      }
      data[instrument.faceunit_instrument_id] += price * +ownedInstrument.quantity || 0
    }

    return data
  }

  async function calculateCharts() {
    if (!portfolio.value) {
      charts.value = {
        investedAmountByCurrency: [],
        equityByCurrency: [],
      }
      return
    }

    charts.value.investedAmountByCurrency = await calculateCurrencyChart(portfolio.value.added_base_instruments_quantities)
    charts.value.equityByCurrency = await calculateCurrencyChart(await calculateEquityChartData())
  }

  async function calculatePortfolioDetails() {
    if (!portfolio.value) {
      return
    }

    const allInstruments: Record<string, (OwnedInstrument & WishedInstrument) | OwnedInstrument | WishedInstrument> = {}

    for (const instrument of portfolio.value.instruments) {
      allInstruments[instrument.instrument_id] = instrument
    }

    for (const instrument of portfolio.value.wishes_by_instrument_id) {
      const addedInstrument = allInstruments[instrument.instrument_id]

      if (!addedInstrument) {
        allInstruments[instrument.instrument_id] = instrument
        continue
      }

      allInstruments[instrument.instrument_id] = {
        ...addedInstrument,
        ...instrument,
      }
    }

    const instruments = await Promise.all(
      Object.values(allInstruments).map(async (i) => {
        const instrument = await instrumentsStore.getInstrument(i.instrument_id)

        const quantity = "quantity" in i ? +i.quantity : 0

        const price = +instrument.price_by_base_instrument_id[portfolio.value?.base_instrument_identifier as Currency]

        return {
          ...instrument,
          initialPrice: "avg_price" in i ? Math.abs(+i.avg_price) : 0,
          quantity,
          price,
          totalPrice: price * quantity,
          share: 0,
          targetPercents: "target_percents" in i ? +i.target_percents : undefined,
          deviationPercents: "deviation_percents" in i ? +i.deviation_percents : undefined,
        }
      })
    )
    const instrumentsGroupedByType = groupBy(instruments, "trade0instrument0type_id")

    const types = []
    for (const typeId in instrumentsGroupedByType) {
      const targetPercents = Number(
        portfolio.value.target_percents_by_instrument_type_id.find((item) => item.instrument_type_id === typeId)?.target_percents
      )

      const type = {
        typeId,
        typeName: instrumentsStore.types[typeId],
        share: 0,
        targetPercents: Number.isNaN(targetPercents) ? undefined : targetPercents,
        totalPrice: 0,
        instruments: instrumentsGroupedByType[typeId],
      }

      types.push(type)
    }

    for (const type of types) {
      type.totalPrice = type.instruments.reduce((sum, i) => sum + Math.max(i.totalPrice, 0), 0)
    }

    const totalPrice = types
      .map((type) => type.instruments)
      .flat()
      .map((i) => Math.max(i.totalPrice, 0))
      .reduce((sum, a) => sum + a, 0)

    for (const type of types) {
      type.share = Math.max(type.totalPrice / totalPrice, 0) || 0

      for (const instrument of type.instruments) {
        instrument.share = Math.max(instrument.totalPrice / totalPrice, 0) || 0
      }
    }

    const targetPrice = +portfolio.value.equity_wish_amounts_by_instrument_id[portfolio.value.base_instrument_identifier]
    const progress = Math.min(totalPrice / targetPrice, 1)

    portfolioDetails.value = {
      progress,
      targetPrice,
      periodTarget: +portfolio.value.equity_wish_addition_amounts_by_instrument_id[portfolio.value.base_instrument_identifier],
      periodTotal: +portfolio.value.added_equity,
      period: portfolio.value.equity_addition_threshold || undefined,
      totalPrice,
      types,
    }

    loadedBaseInstrumentId.value = portfolio.value.base_instrument_identifier
  }

  return {
    userId,
    baseInstrumentId: loadedBaseInstrumentId,
    baseInstrumentSign: loadedBaseInstrumentSign,
    reloadPortfolio,
    updatePortfolio,
    updatePortfolios,
    updateBaseInstrument,
    portfolio,
    portfolios,
    portfolioDetails,
    hasPlan,
    isEmpty,
    charts,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePortfolioStore, import.meta.hot))
}
