import * as Sentry from "@sentry/vue"

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  Sentry.init({
    release: `rebalance@${config.public.version}`,
    enabled: process.env.NODE_ENV === "production",
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  })
})
