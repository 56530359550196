export default defineNuxtRouteMiddleware((to) => {
  const user = useUserStore()
  const needsToBeAdmin = to.path.startsWith("/admin")

  if (!needsToBeAdmin || user.isAdmin) {
    return
  }

  return navigateTo("/")
})
