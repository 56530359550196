import vModalVue from "~/components/blocks/v-modal/v-modal.vue"

export function useModalStep(element: MaybeRef<InstanceType<typeof vModalVue>>, { initialStep = 1 }: { initialStep?: number } = {}) {
  const modal = ref(element)
  const step = ref(initialStep)

  watch(step, () => {
    modal.value?.resetScroll()
  })

  return { step }
}
