<template>
  <NuxtLoadingIndicator color="#2C58F3" />
  <div class="grid min-h-full justify-items-stretch text-16 text-232335">
    <NuxtLayout>
      <!-- we need to keep alive portfolio page so that we can cache images etc for smooth navigation between portfolio and instrument page on mobile devices -->
      <NuxtPage
        :keepalive="{
          include: 'PortfolioPage',
          max: 1,
        }"
      />
    </NuxtLayout>

    <v-modals />
  </div>
</template>

<script setup lang="ts">
useHead({
  title: "AW Finance",
  meta: [
    {
      name: "description",
      content: "Платформа для инвесторов по ведению портфеля",
    },
  ],
  link: [
    {
      rel: "manifest",
      href: "/manifest.webmanifest",
    },
  ],
})
</script>

<style lang="scss">
@import "@/assets/css/styles.scss";
</style>
