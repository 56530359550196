<template>
  <div>
    <Doughnut
      :data="chartData"
      :options="options"
    />
  </div>
</template>

<script setup lang="ts">
import { Chart as ChartJS, ArcElement } from "chart.js"
import { Doughnut } from "vue-chartjs"

const props = withDefaults(
  defineProps<{
    data: number[]
    colors?: string[]
    cutout?: number
  }>(),
  {
    colors: () => ["#C4C8DD", "#95FFA5", "#629EFF", "#FFB968", "#FFF068"],
    cutout: 50,
  }
)

const chartData = computed(() => ({
  datasets: [
    {
      backgroundColor: props.colors,
      hoverBackgroundColor: props.colors,
      data: props.data.length ? props.data : [1],
      borderWidth: 0,
    },
  ],
}))

const options = computed(() => ({
  cutout: props.cutout,
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
}))

ChartJS.register(ArcElement)
</script>
