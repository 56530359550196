import * as Sentry from "@sentry/vue"

import { useStorage } from "@vueuse/core"
import { clone } from "lodash-es"
import { User } from "~/models/api"

const emptyUser: User = {
  id: "0",
  first_name: "",
  last_name: "",
  username: "",
  photo_url: "",
  telegram_identifier: "",
  premium_expires_at: "",
  is_admin: null,
  discount_expires_at: null,
  premium_period: null,
  active_purchase_id: null,
  ban_reason: null,
}

export const useUserStore = defineStore("user", () => {
  const details = ref<User>(clone(emptyUser))

  const loginRedirectPath = ref("/")

  const token = useAPIToken()
  const isAuthorized = computed(() => Boolean(+details.value.id))
  const isBanned = ref(false)
  const isAdmin = computed(() => Boolean(details.value.is_admin))
  const premiumExpirationDate = computed(() => {
    if (!details.value.premium_expires_at) {
      return new Date(0)
    }

    return new Date(+details.value.premium_expires_at * 1000)
  })
  const isPremiumActive = computed(() => Date.now() < +premiumExpirationDate.value)
  const triedPremium = computed(() => Boolean(details.value.premium_expires_at))
  const isPremiumExpired = computed(() => triedPremium.value && !isPremiumActive.value)
  const isPremiumCancelled = computed(() => triedPremium.value && !details.value.active_purchase_id)
  const role = computed(() => (isAuthorized.value ? "user" : "guest"))
  const completedIntro = useStorage<boolean>("completedIntro", false, localStorage)

  watchEffect(() => {
    if (!isAuthorized.value) {
      Sentry.setUser(null)
      return
    }

    Sentry.setUser({ id: details.value.id, username: details.value.username })
  })

  async function login(newToken: string) {
    token.value = newToken
    // event loop stuff with useStorage I guess, no time to fix
    await nextTick()

    const response = await getUser({ silent: true })
    if (!response.data.success) {
      alert("Что-то пошло не так, пожалуйста, попробуйте снова")
      return
    }

    details.value = response.data.data
    navigateTo(loginRedirectPath.value)
  }

  function logout() {
    token.value = null
    details.value = clone(emptyUser)
    navigateTo("/auth")
  }

  async function fetchUser() {
    const response = await getUser({ silent: true })
    if (response.data.success) {
      details.value = response.data.data
    }

    isBanned.value = response.data.errors.includes("ERROR_USER_BANNED")
  }

  return {
    details,
    premiumExpirationDate,
    isPremiumActive,
    isPremiumExpired,
    isPremiumCancelled,
    triedPremium,
    fetch: fetchUser,
    loginRedirectPath,
    isAuthorized,
    isBanned,
    isAdmin,
    role,
    login,
    logout,
    completedIntro,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
