<template>
  <div class="md:text-center">
    <v-modal-heading class="mb-16 md:mb-8">Создание портфеля</v-modal-heading>
    <p class="mb-24 text-424250">Выберите один из типов подходящего для вас портфеля</p>

    <div class="flex flex-col items-stretch gap-24">
      <button
        v-for="portfolioType of types"
        :key="portfolioType.key"
        class="flex h-[268px] cursor-pointer items-center justify-center gap-24 rounded-20 border bg-f9f9f9 px-20 py-36 md:h-auto md:flex-col md:gap-12 md:px-16 md:py-16"
        :class="{
          'border-c7c6cd': modelValue !== portfolioType.key,
          'border-2c58f3': modelValue === portfolioType.key,
        }"
        @click="emit('update:modelValue', portfolioType.key)"
      >
        <img
          :src="portfolioType.img"
          class="h-[160px] w-[240px] object-contain md:h-[120px] md:w-[170px]"
        />
        <div class="max-w-[220px] text-left md:max-w-full md:text-center">
          <p class="mb-6 text-18 font-bold">{{ portfolioType.title }}</p>
          <p class="text-16">{{ portfolioType.description }}</p>
        </div>
      </button>
    </div>

    <v-modal-footer>
      <v-button
        :disabled="!modelValue"
        class="mt-24"
        @click="emit('next')"
      >
        Продолжить
      </v-button>
    </v-modal-footer>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  modelValue?: "with-assets" | "without-assets"
}>()

const emit = defineEmits<{
  (e: "update:modelValue", value: "with-assets" | "without-assets"): void
  (e: "next"): void
}>()

const { getImage } = useAssets()

const types = computed(() => [
  {
    key: "without-assets" as const,
    img: getImage("illustrations/man-reading-script.png"),
    title: "Нет активов",
    description: "если хотите создать портфель заново, без текущих активов, или создать новый портфель",
  },
  {
    key: "with-assets" as const,
    img: getImage("illustrations/man-writing.png"),
    title: "Есть активы",
    description: "если вы хотите создать портфель, в который уже куплены активы",
  },
])
</script>
