<template>
  <v-container class="mx-auto flex min-h-full flex-col items-center py-20">
    <nuxt-link to="/">
      <v-logo />
    </nuxt-link>

    <div class="my-auto text-center">
      <h1 class="text-100 font-bold">{{ props.error.statusCode }}</h1>
      <p class="text-20">{{ message }}</p>
      <p
        v-if="showStack"
        class="mt-10"
      >
        {{ props.error.stack }}
      </p>
      <v-button
        class="mx-auto mt-40"
        @click="navigateTo('/')"
      >
        На главную
      </v-button>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { NuxtError } from "nuxt/app"

const props = defineProps<{
  error: NuxtError
}>()

const message = computed(() => {
  switch (props.error.statusCode) {
    case 404:
      return `Страница не найдена`
    default:
      return props.error.message
  }
})

const showStack = computed(() => {
  return props.error.stack && props.error.statusCode !== 404
})
</script>
