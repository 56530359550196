import { Config } from "~/models/api"

export function getConfig() {
  return get<Config>("/config/get/")
}

export function editConfigItem({ key, value }: { key: string; value: string }) {
  return post("/config/setByKey/", {
    key,
    value,
  })
}

export function deleteConfigItem({ key }: { key: string }) {
  return post("/config/deleteByKey/", {
    key,
  })
}
