<template>
  <v-modal ref="modal">
    <v-stepper
      :step="step"
      :steps="steps"
      :back-button="step > 1"
      class="mb-24"
      @back="step--"
    />

    <modal-edit-strategy-step-desired-assets v-if="step === 1" />
    <modal-edit-strategy-step-distribute-assets v-else-if="step === 2" />
    <modal-edit-strategy-step-goal v-else-if="step === 3" />
  </v-modal>
</template>

<script setup lang="ts">
import { InvestmentFrequency } from "~/models/api"

const baseInstrument = useBaseInstrument()

const modal = ref()
const { step } = useModalStep(modal)
const steps = 3

const instrumentsStore = useInstrumentsStore()

const portfolioStore = usePortfolioStore()
const ownedInstruments = ref<string[]>(portfolioStore.portfolio?.instruments.map((i) => i.instrument_id) || [])
const desiredInstruments = ref<string[]>(
  portfolioStore.portfolio?.wishes_by_instrument_id.map((i) => i.instrument_id).filter((desiredId) => !ownedInstruments.value.includes(desiredId)) ||
    []
)
const allInstruments = computed(() => [desiredInstruments.value, ownedInstruments.value].flat().filter(Boolean))
const distributionByTypes = ref<Record<string, number>>(
  Object.fromEntries(portfolioStore.portfolio?.target_percents_by_instrument_type_id.map((t) => [t.instrument_type_id, +t.target_percents]) || [])
)
const distributionByInstruments = ref<Record<string, number>>(
  Object.fromEntries(portfolioStore.portfolio?.wishes_by_instrument_id.map((t) => [t.instrument_id, +t.target_percents]) || [])
)

watch(
  desiredInstruments,
  async () => {
    for (const id in distributionByInstruments.value) {
      if (!allInstruments.value.includes(id)) {
        delete distributionByInstruments.value[id]
      }
    }

    const allTypes = new Set(
      (await Promise.all(allInstruments.value.map((instr) => instrumentsStore.getInstrument(instr)))).map((instr) => instr.trade0instrument0type_id)
    )
    for (const id in distributionByTypes.value) {
      if (!allTypes.has(id)) {
        delete distributionByTypes.value[id]
      }
    }
  },
  {
    deep: true,
  }
)

const targetBalance = ref<undefined | number>(
  portfolioStore.portfolio ? +portfolioStore.portfolio.equity_wish_amounts_by_instrument_id[baseInstrument.id.value] || undefined : undefined
)
const recurringInvestmentAmount = ref<undefined | number>(
  portfolioStore.portfolio ? +portfolioStore.portfolio.equity_wish_addition_amounts_by_instrument_id[baseInstrument.id.value] || undefined : undefined
)
const investmentFrequency = ref<InvestmentFrequency>(portfolioStore.portfolio?.equity_addition_threshold || InvestmentFrequency.monthly)

provide("step", step)
provide("ownedInstruments", ownedInstruments)
provide("desiredInstruments", desiredInstruments)
provide("distributionByTypes", distributionByTypes)
provide("distributionByInstruments", distributionByInstruments)
provide("targetBalance", targetBalance)
provide("recurringInvestmentAmount", recurringInvestmentAmount)
provide("investmentFrequency", investmentFrequency)
</script>
