<template>
  <v-modal>
    <v-modal-heading class="mb-24">Инструкция по работе с сервисом</v-modal-heading>

    <v-youtube
      :src="instructionLink"
      cover="intro-video-cover.jpg"
    />
  </v-modal>
</template>

<script setup lang="ts">
const { config } = useConfigStore()
const screen = useScreen()
const instructionLink = computed(() => {
  return screen.value.md ? config.INTRO_1_STEP_VIDEO_LINK_MOBILE : config.INTRO_1_STEP_VIDEO_LINK_DESKTOP
})
</script>
