<template>
  <div
    v-if="value"
    ref="modalWrapperRef"
    class="fixed inset-0 z-50 overflow-auto bg-232335/30 px-20 py-60"
    :class="{
      'md:px-0 md:py-0': mobileType === 'fullscreen',
      'md:flex md:px-16 md:py-16': mobileType === 'normal',
    }"
  >
    <!-- .transform-gpu so safari will render shadows correctly -->
    <div
      v-bind="$attrs"
      ref="modalRef"
      class="relative mx-auto transform-gpu cursor-default rounded-30 bg-white p-40 shadow-xl shadow-black/5"
      :class="{
        'md:min-h-full md:max-w-full md:rounded-none md:px-16 md:py-40 md:pt-50 md:shadow-none': mobileType === 'fullscreen',
        'md:my-auto md:w-full md:px-22 md:pb-22 md:pt-40': mobileType === 'normal',
      }"
    >
      <button
        class="absolute right-16 top-16"
        @click="value = false"
      >
        <Icon
          name="iconoir:cancel"
          size="30px"
        />
      </button>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    modelValue?: boolean
    mobileType?: "fullscreen" | "normal"
  }>(),
  {
    modelValue: false,
    mobileType: "fullscreen",
  }
)

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void
}>()

defineExpose({
  resetScroll,
})

const value = ref(props.modelValue)
watch(
  () => props.modelValue,
  () => {
    value.value = props.modelValue
  }
)

useHead({
  bodyAttrs: {
    class: computed(() => {
      if (value.value) {
        return "overscroll-none"
      }

      return ""
    }),
  },
})

const isScrollLocked = useScrollLock(document)
watchEffect(() => {
  isScrollLocked.value = value.value
})

watch(value, () => {
  if (value.value !== props.modelValue) {
    emit("update:modelValue", value.value)
  }
})

const modalRef = ref<HTMLElement>()

const modalWrapperRef = ref<HTMLElement>()
function resetScroll() {
  if (modalWrapperRef.value) {
    modalWrapperRef.value.scrollTop = 0
  }
}
</script>
