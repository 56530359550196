<template>
  <div>
    <v-modal-heading class="mb-24">Внесите изменения</v-modal-heading>
    <p class="mb-4">Вы собираетесь сделать изменения на сумму:</p>
    <p class="mb-16 text-26 font-bold">{{ formatMoney(sum) }} {{ baseInstrument.sign.value }}</p>

    <p class="mb-24">Зайдите в приложение биржи и совершите следующие операции:</p>

    <div class="flex flex-col gap-16">
      <div
        v-for="key in visibleSuggestionKeys"
        :key="key"
      >
        <h3 class="mb-16 text-18 font-bold">{{ dict[key].title }}</h3>
        <div class="flex flex-col gap-16 md:gap-20">
          <div
            v-for="instr of visibleSuggestion[key]"
            :key="instr.instrument.id"
            class="grid grid-cols-[1fr_125px_150px_150px] gap-16 md:grid-cols-[1fr_0.7fr] md:gap-10"
          >
            <v-input
              :placeholder="'Актив' + (instr.instrument.lotsize ? ` (мин. ${formatLotSize(instr.instrument.lotsize)} шт.)` : '')"
              :model-value="instr.instrument.secid || instr.instrument.isin"
              disabled
            >
              <template #icon>
                <v-asset-icon
                  :type-id="instr.instrument.trade0instrument0type_id"
                  light
                />
              </template>
            </v-input>
            <v-number-input
              v-model="instr.userQuantity"
              :maximum-fraction-digits="10"
              placeholder="Кол-во штук"
            />
            <v-base-instrument-number-input
              v-model="instr.userPrice"
              placeholder="Сумма"
            />
            <v-button
              :variant="fulfilledSuggestions.includes(instr.instrument.id) ? 'default' : 'outlined-without-bg'"
              icon="iconoir:check"
              class="shrink-0"
              :class="{
                '!text-13a906 hover:!bg-transparent': !fulfilledSuggestions.includes(instr.instrument.id),
              }"
              @click="toggleSuggestion(instr.instrument.id)"
            >
              {{ dict[key].fulfilled }}
            </v-button>
          </div>
        </div>
      </div>
    </div>

    <v-modal-footer>
      <v-button
        class="mt-24"
        :loading="submitting"
        :disabled="!canSubmit"
        @click="submit"
      >
        Сохранить изменения
      </v-button>
    </v-modal-footer>
  </div>
</template>

<script setup lang="ts">
import { RebalanceSuggestionView } from "./index.vue"

const baseInstrument = useBaseInstrument()

const balance = inject("balance") as Ref<number>
const additionalMoney = inject("additionalMoney") as Ref<number>
const suggestion = inject("suggestion") as Ref<RebalanceSuggestionView | undefined>
const rejectedSuggestions = inject("rejectedSuggestions") as Ref<string[]>

const visibleSuggestion = computed<Partial<RebalanceSuggestionView>>(() => {
  if (!suggestion.value) {
    return {}
  }

  return Object.fromEntries(
    Object.entries(suggestion.value)
      .map(([key, entry]) => [
        key,
        entry.filter((instr) => !rejectedSuggestions.value.includes(instr.instrument.id) && instr.instrument.id !== baseInstrument.id.value),
      ])
      .filter(([, entry]) => entry.length)
  )
})
const visibleSuggestionKeys = computed(() => {
  const keys = ["sell", "buy"] satisfies (keyof typeof visibleSuggestion.value)[]
  return keys.filter((key) => key in visibleSuggestion.value)
})

const dict = {
  buy: {
    title: "Купить",
    fulfilled: "Куплено",
    sum: "Общая сумма покупки составит",
  },
  sell: {
    title: "Продать",
    fulfilled: "Продано",
    sum: "Общая сумма продажи составит",
  },
}

const fulfilledSuggestions = ref<string[]>([baseInstrument.id.value])
const sum = computed(() => {
  if (!suggestion.value) {
    return 0
  }

  const sellSum = visibleSuggestion.value.sell?.reduce((sum, instr) => sum + instr.userPrice, 0) || 0
  const buySum = visibleSuggestion.value.buy?.reduce((sum, instr) => sum + instr.userPrice, 0) || 0

  if (!sellSum) {
    return buySum
  }

  if (!buySum) {
    return sellSum
  }

  return balance.value + additionalMoney.value + sellSum - buySum
})

function toggleSuggestion(id: string) {
  if (fulfilledSuggestions.value.includes(id)) {
    const idx = fulfilledSuggestions.value.indexOf(id)
    fulfilledSuggestions.value.splice(idx, 1)
    return
  }

  fulfilledSuggestions.value.push(id)
}

const canSubmit = computed(() => fulfilledSuggestions.value.some((id) => id !== baseInstrument.id.value))

const submitting = ref(false)
async function submit() {
  submitting.value = true

  const portfolioStore = usePortfolioStore()

  try {
    const response = await editPortfolio({
      id: portfolioStore.portfolio?.id,
      buy: mapSuggestedInstruments(suggestion.value?.buy),
      sell: mapSuggestedInstruments(suggestion.value?.sell),
    })

    if (response.data.success) {
      await portfolioStore.updatePortfolio()

      const modalsStore = useModalsStore()
      modalsStore.hide()
    }
  } finally {
    submitting.value = false
  }
}

function mapSuggestedInstruments(instruments?: RebalanceSuggestionView["buy"]) {
  if (!instruments) {
    return []
  }

  return instruments
    .filter((instr) => fulfilledSuggestions.value.includes(instr.instrument.id))
    .map((instr) => ({ id: instr.instrument.id, quantity: instr.userQuantity, total: instr.userPrice }))
}
</script>
