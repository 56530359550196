<template>
  <v-modal mobile-type="normal">
    <h2 class="mb-16 text-22 font-bold">Удаление комментария</h2>
    <p class="mb-24">Вы уверены, что хотите удалить комментарий?</p>
    <v-modal-footer class="!justify-start">
      <v-button
        variant="danger"
        :loading="submitting"
        @click="submit"
      >
        Да, удалить
      </v-button>
      <v-button
        variant="outlined-without-bg"
        @click="cancel"
      >
        Нет, отменить
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const options = useModalOptions<"delete-instrument-comment">()

const route = useRoute()
const modalsStore = useModalsStore()

function cancel() {
  if (route.name === "portfolioInstrument") {
    modalsStore.hide()
  } else {
    modalsStore.show("instrument-comments", { id: options.value.instrumentId })
  }
}

const submitting = ref(false)
async function submit() {
  submitting.value = true

  const response = await deleteInstrumentComment({
    commentId: options.value.commentId,
  })

  if (!response.data.success) {
    submitting.value = false
    return
  }

  const instrumentsStore = useInstrumentsStore()
  await instrumentsStore.reloadInstrument(options.value.instrumentId)

  const portfolioStore = usePortfolioStore()
  await portfolioStore.reloadPortfolio()

  submitting.value = false

  if (route.name === "portfolioInstrument") {
    modalsStore.hide()
  } else {
    modalsStore.show("instrument-comments", { id: options.value.instrumentId })
  }
}
</script>
