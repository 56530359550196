<template>
  <div
    class="flex cursor-pointer items-stretch rounded-full"
    :class="{
      'bg-ababab': !value,
      'bg-2c58f3': value,
      'h-25 w-45 p-3': size === 'md',
      'h-30 w-55 p-4': size === 'lg',
    }"
    @click="value = !value"
  >
    <div
      class="rounded-full bg-white transition-all"
      :class="{
        'w-19': size === 'md',
        'translate-x-[calc(100%+1px)]': value && size === 'md',
        'w-22': size === 'lg',
        'translate-x-[calc(100%+3px)]': value && size === 'lg',
      }"
    ></div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: boolean
    size?: "md" | "lg"
  }>(),
  {
    modelValue: false,
    size: "md",
  }
)

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void
}>()

const value = ref(props.modelValue)
watch(
  () => props.modelValue,
  () => {
    value.value = props.modelValue
  }
)

watch(value, () => {
  if (value.value !== props.modelValue) {
    emit("update:modelValue", value.value)
  }
})
</script>
