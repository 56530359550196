import { TradeInstrument, TradeInstrumentType } from "~/models/api"

export function findInstruments({
  q,
  ids,
  tradeProvider,
  tradeInstrumentType,
}: { q?: string; ids?: (number | string)[]; tradeProvider?: string; tradeInstrumentType?: string } = {}) {
  return get<TradeInstrument[]>("/tradeInstruments/find/", {
    params: {
      q,
      ids,
      trade_provider_id: tradeProvider,
      trade_instrument_type: tradeInstrumentType,
    },
  })
}

export function findInstrumentsTypes() {
  return get<TradeInstrumentType[]>("/tradeInstrumentsTypes/find/")
}

export function addInstrumentComment({ instrumentId, comment }: { instrumentId: string; comment: string }) {
  return post("/tradeInstruments/addPersonalComment/", {
    id: instrumentId,
    comment,
  })
}

export function deleteInstrumentComment({ commentId }: { commentId: string }) {
  return post("/tradeInstruments/deletePersonalComment/", {
    id: commentId,
  })
}
