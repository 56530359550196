import {
  Currency,
  DistributionInput,
  InvestmentFrequency,
  Operation,
  OwnedTradeInstrumentInput,
  Portfolio,
  RebalanceStrategy,
  RebalanceSuggestion,
} from "~/models/api"

export function findPortfolios({ userId, id }: { userId?: string; id?: string } = {}) {
  return get<Portfolio[]>("/portfolios/find/", {
    params: {
      user_id: userId,
      id,
    },
  })
}

export function editPortfolio({
  id,
  name,
  emoji,
  buy,
  sell,
  desiredInstruments,
  typesDistribution,
  ignoreLotSize,
  targetBalance,
  investmentFrequency,
  recurringInvestmentAmount,
  baseInstrumentId,
}: {
  id?: string
  name?: string
  emoji?: string
  buy?: OwnedTradeInstrumentInput[]
  sell?: OwnedTradeInstrumentInput[]
  desiredInstruments?: DistributionInput[]
  typesDistribution?: DistributionInput[]
  ignoreLotSize?: boolean
  targetBalance?: number
  investmentFrequency?: InvestmentFrequency
  recurringInvestmentAmount?: number
  baseInstrumentId?: Currency
}) {
  return post<Portfolio>("/portfolios/edit/", {
    base_instrument_id: baseInstrumentId || useBaseInstrument().id.value,
    base_instrument_identifier: baseInstrumentId,
    id,
    name,
    emoji,
    target_percents_by_instrument_type_id: typesDistribution
      ? typesDistribution.map((o) => ({
          instrument_type_id: o.id,
          target_percents: o.targetPercents,
        }))
      : undefined,
    instruments_by_operation: {
      wish: desiredInstruments
        ? desiredInstruments.map((o) => ({
            id: o.id,
            target_percents: o.targetPercents,
          }))
        : undefined,
      buy,
      sell,
    },
    ignore_lotsize: Number(ignoreLotSize || false),
    equity_wish_amount: targetBalance,
    equity_addition_threshold: investmentFrequency,
    equity_addition_amount: recurringInvestmentAmount,
  })
}

export function deletePortfolio(id: string) {
  return post("/portfolios/delete/", { id })
}

export function suggestRebalance({
  id,
  additionalMoney,
  useBalance,
  doNotSell,
  strategy,
  silent,
}: {
  id: string
  additionalMoney: number
  useBalance: boolean
  doNotSell: boolean
  strategy: RebalanceStrategy
  silent?: boolean
}) {
  return get<RebalanceSuggestion>("/portfolios/suggestRebalance/", {
    params: {
      id,
      use_existing_base_instrument_equity: Number(useBalance),
      do_not_sale: Number(doNotSell),
      base_instrument_id: useBaseInstrument().id.value,
      base_instrument_additional_equity: additionalMoney,
      strategy,
    },
    silent,
  })
}

export function findPortfolioOperations(id: string) {
  return get<Operation[]>("/portfolioOperations/find/", {
    params: {
      portfolio_id: id,
      base_instrument_id: useBaseInstrument().id.value,
    },
  })
}
