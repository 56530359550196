<template>
  <div
    class="relative"
    :class="{
      'cursor-not-allowed': props.disabled,
    }"
  >
    <div
      v-if="hasIcon"
      class="pointer-events-none absolute inset-y-0 left-8 m-auto h-fit"
    >
      <slot name="icon">
        <v-field-icon
          :path="props.iconPath"
          :src="props.iconSrc"
        />
      </slot>
    </div>

    <span
      ref="mockInput"
      class="pointer-events-none absolute -z-10 max-w-full overflow-hidden text-transparent"
    >
      {{ value }}
    </span>

    <input
      v-bind="props.inputAttrs"
      v-model="value"
      class="w-full rounded-12 border border-e1e1e9 pb-6 pr-14 pt-20"
      :class="{
        'pl-14': !hasIcon,
        'pl-54': hasIcon,
        'bg-white': !props.disabled,
        'cursor-not-allowed bg-f0f1f4 opacity-100 [-webkit-text-fill-color:#232335]': props.disabled,
        'outline outline-2 -outline-offset-1 outline-2c58f3': isFocused,
      }"
      :disabled="props.disabled"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @keydown="onKeydown"
    />

    <Transition>
      <span
        v-if="internalHint && isPlaceholderStacked && !isFocused"
        class="pointer-events-none absolute bottom-0 right-14 top-0 overflow-hidden text-ellipsis whitespace-nowrap border border-transparent pb-6 pt-20 text-84849b"
        :style="{ left: `min(100%, ${internalHintLeftPosition})` }"
      >
        {{ internalHint }}
      </span>
    </Transition>

    <span
      v-if="placeholder"
      class="pointer-events-none absolute left-14 right-14 top-7 h-fit overflow-hidden text-ellipsis whitespace-nowrap font-medium text-84849b transition-all"
      :class="{
        'left-14': !hasIcon,
        'left-54': hasIcon,
        'text-11': isPlaceholderStacked,
        'translate-y-7 pb-2': !isPlaceholderStacked,
      }"
    >
      {{ props.placeholder }}
      <span
        v-if="props.required"
        class="text-f83d3d"
      >
        *
      </span>
    </span>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: string
    placeholder?: string
    stackPlaceholder?: boolean
    disabled?: boolean
    required?: boolean
    iconPath?: string
    iconSrc?: string
    allowedSymbols?: RegExp
    inputAttrs?: Record<string, unknown>
    internalHint?: string
  }>(),
  {
    modelValue: "",
    placeholder: "",
    disabled: false,
    internalHint: "",
  }
)

const emit = defineEmits<{
  "update:modelValue": [value: string]
  "update:isFocused": [value: boolean]
}>()

const slots = useSlots()

const value = ref(props.modelValue)

watch(
  () => props.modelValue,
  () => {
    value.value = props.modelValue
  }
)

watch(value, () => {
  if (value.value !== props.modelValue) {
    emit("update:modelValue", value.value)
  }
})

const isFocused = ref(false)
watch(isFocused, () => {
  emit("update:isFocused", isFocused.value)
})

const isPlaceholderStacked = computed(() => props.stackPlaceholder || isFocused.value || value.value)

const hasIcon = computed(() => slots.icon || props.iconPath || props.iconSrc)

function onKeydown(event: KeyboardEvent) {
  if (!props.allowedSymbols || props.allowedSymbols.test(event.key) || event.ctrlKey || event.metaKey || event.key.length > 1) {
    return
  }

  event.preventDefault()
}

const mockInput = ref<HTMLInputElement>()
const { width: mockInputWidth } = useElementSize(mockInput)

const internalHintLeftPosition = computed(() => 14 + mockInputWidth.value + 8 + "px")
</script>
