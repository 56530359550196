<template>
  <v-modal ref="modal">
    <v-stepper
      :step="step"
      :steps="steps"
      :back-button="step > 1"
      class="mb-24"
      @back="step--"
    />

    <modal-add-portfolio-changes-step-finances v-if="step === 1" />
    <modal-add-portfolio-changes-step-confirm v-else-if="step === 2" />
    <modal-add-portfolio-changes-step-save v-else />
  </v-modal>
</template>

<script setup lang="ts">
import { RebalanceSuggestion, TradeInstrument } from "~/models/api"

export type RebalanceSuggestionView = Record<
  keyof RebalanceSuggestion["suggestions"],
  {
    quantity: number
    userQuantity: number
    price: number
    userPrice: number
    instrument: TradeInstrument
  }[]
>

const portfolioStore = usePortfolioStore()

const modal = ref()
const { step } = useModalStep(modal)
const steps = 3

const baseInstrument = useBaseInstrument()
const instrumentsStore = useInstrumentsStore()

const balance = computed(() => {
  if (!portfolioStore.portfolio) {
    return 0
  }

  const instrument = portfolioStore.portfolio.instruments.find((instr) => instr.instrument_id === baseInstrument.id.value)

  return Number(instrument?.quantity) || 0
})
const useBalanceMoney = ref(true)
const doNotSell = ref(false)
const additionalMoney = ref(0)
const originalSuggestion = ref<RebalanceSuggestion["suggestions"]>({
  buy: [],
  sell: [],
})

const suggestion = ref<RebalanceSuggestionView>()
watch(
  originalSuggestion,
  async () => {
    const instrumentsIds = Object.values(originalSuggestion.value)
      .flat()
      .map((s) => s.trade_instrument_id)

    const instruments = await Promise.all(instrumentsIds.map((id) => instrumentsStore.getInstrument(id)))
    const instrumentsById = Object.fromEntries(instruments.map((instr) => [instr.id, instr]))

    suggestion.value = Object.fromEntries(
      Object.entries(originalSuggestion.value).map(([key, instrs]) => [
        key,
        instrs.map((instr) => ({
          instrument: instrumentsById[instr.trade_instrument_id],
          quantity: +instr.quantity,
          userQuantity: +instr.quantity,
          price: +instr.quantity * +instrumentsById[instr.trade_instrument_id].price_by_base_instrument_id[baseInstrument.id.value],
          userPrice: +instr.quantity * +instrumentsById[instr.trade_instrument_id].price_by_base_instrument_id[baseInstrument.id.value],
        })),
      ])
    ) as RebalanceSuggestionView
  },
  { immediate: true }
)

const rejectedSuggestions = ref<string[]>([])
const fulfilledSuggestions = ref<string[]>([])

provide("step", step)
provide("balance", balance)
provide("useBalanceMoney", useBalanceMoney)
provide("doNotSell", doNotSell)
provide("additionalMoney", additionalMoney)
provide("originalSuggestion", originalSuggestion)
provide("suggestion", suggestion)
provide("rejectedSuggestions", rejectedSuggestions)
provide("fulfilledSuggestions", fulfilledSuggestions)
</script>
