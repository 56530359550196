import { Currency } from "~/models/api"

const config = {
  [Currency.RUB]: {
    name: "Рубли",
    sign: "₽",
    sortingPosition: 0,
  },
  [Currency.USD]: {
    name: "Доллары",
    sign: "$",
    sortingPosition: 1,
  },
  [Currency.EUR]: {
    name: "Евро",
    sign: "€",
    sortingPosition: 2,
  },
}

export const currency = {
  getSign(id: string) {
    return config[id as Currency]?.sign ?? "?"
  },
  getName(id: string) {
    return config[id as Currency]?.name ?? "Неизвестная валюта"
  },
  getSortingPosition(id: string) {
    return config[id as Currency]?.sortingPosition ?? 3
  },
}
