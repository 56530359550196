import { Payment, PaymentPreparation, SubscriptionPeriod } from "~/models/api"

export function preparePayments({ code, period, email }: { code?: string; period: SubscriptionPeriod; email: string }) {
  return post<PaymentPreparation>("/payments/prepare/", {
    code,
    period,
    email,
  })
}

export function findPayments({ userId, id }: { userId?: string; id?: string } = {}) {
  return get<Payment[]>("/payments/find/", {
    params: {
      user_id: userId,
      id,
    },
  })
}

export function cancelPayment(id: string) {
  return post("/payments/cancel/", {
    id,
  })
}
