<template>
  <v-modal-heading class="mb-24">Создание портфеля</v-modal-heading>

  <modal-shared-portfolio-name class="mb-16" />

  <modal-shared-base-instrument-select class="mb-24" />

  <h2 class="mb-16 text-18 font-bold">Добавьте желаемые активы</h2>
  <modal-shared-desired-assets />

  <v-modal-footer class="mt-24">
    <v-button
      :disabled="!emoji || !name || !instruments.length || !instruments.every(Boolean)"
      @click="emit('next')"
    >
      Продолжить
    </v-button>
  </v-modal-footer>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  next: []
}>()

const emoji = inject("emoji") as Ref<string>
const name = inject("name") as Ref<string>
const instruments = inject("desiredInstruments") as Ref<string[]>
</script>
