export default function useAssets() {
  const images = computed<Record<string, { default: string }>>(() => import.meta.glob("/assets/images/**/*.(png|jpg|jpeg|svg)", { eager: true }))

  const getImage = (src: string): string | undefined => {
    for (const path in images.value) {
      const image = images.value[path].default
      if (path.endsWith(`assets/images/${src}`)) return image
    }
    return undefined
  }

  return {
    getImage,
  }
}
