export function useEventBus() {
  const internalInstance = getCurrentInstance()

  if (!internalInstance) {
    throw new Error("useEventBus must be used within a component")
  }

  const emitter = internalInstance.appContext.config.globalProperties.$emitter
  const emitterProxy = new Proxy(emitter, {
    get(target, prop, receiver) {
      const item = Reflect.get(target, prop, receiver)

      if (prop !== "on") {
        return item
      }

      const onFnProxy = new Proxy(item, {
        apply(target, thisArg, argumentsList: Parameters<typeof emitter.on>) {
          onUnmounted(() => {
            emitter.off(...argumentsList)
          })

          return Reflect.apply(target, thisArg, argumentsList)
        },
      })

      return onFnProxy
    },
  })

  return emitterProxy
}
