export type Modals = {
  instruction: Record<string, never>
  "create-portfolio": Record<string, never>
  "rename-portfolio": Record<string, never>
  "delete-portfolio": Record<string, never>
  "add-portfolio-changes": Record<string, never>
  "edit-strategy": Record<string, never>
  "add-asset": Record<string, never>
  "portfolio-charts": Record<string, never>
  "user-settings": Record<string, never>
  "delete-user": {
    id?: string
  }
  "ban-user": {
    id: string
  }
  "unban-user": {
    id: string
  }
  subscription: Record<string, never>
  "cancel-subscription": {
    redirectTo?: string
  }
  "operate-asset": {
    id: string | number
    operation: "buy" | "sell"
  }
  "operations-history": Record<string, never>
  "add-asset-comment": {
    id: string
  }
  "delete-instrument-comment": {
    instrumentId: string
    commentId: string
  }
  "instrument-comments": {
    id: string
  }
  "add-free-access-promo-code": Record<string, never>
  "edit-free-access-promo-code": {
    id: string
  }
  "delete-free-access-promo-code": {
    id: string
  }
  "add-discount-promo-code": Record<string, never>
  "edit-discount-promo-code": {
    id: string
  }
  "delete-discount-promo-code": {
    id: string
  }
  "delete-page": {
    id: string
  }
}

type ModalsState =
  | {
      modal: null
      options: Record<string, never>
    }
  | {
      [K in keyof Modals]: {
        modal: K
        options: Modals[K]
      }
    }[keyof Modals]

export const useModalsStore = defineStore("modals", {
  state: (): ModalsState => ({
    modal: null,
    options: {},
  }),
  actions: {
    async show<T extends keyof Modals>(modal: T, ...options: Modals[T] extends Record<string, never> ? [undefined?] : [Modals[T]]) {
      if (this.modal) {
        this.hide()
        await nextTick()
      }

      this.modal = modal
      this.options = options[0] || {}
    },
    hide() {
      this.modal = null
      this.options = {}
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalsStore, import.meta.hot))
}
