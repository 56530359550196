import { Config, configKeys } from "~/models/api"

export const useConfigStore = defineStore("config", () => {
  const emptyConfig = Object.fromEntries(configKeys.map((key) => [key, ""])) as Config
  const config = ref<Config>(emptyConfig)

  async function fetchConfig() {
    const response = await getConfig()
    config.value = response.data.data
  }

  return {
    fetchConfig,
    config,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
}
