<template>
  <v-modal-heading class="mb-24">Распределите активы</v-modal-heading>

  <modal-shared-distribute-assets v-model:valid="isValid" />

  <v-modal-footer class="mt-38 md:mt-24">
    <v-button
      :disabled="!isValid"
      @click="step++"
    >
      Продолжить
    </v-button>
  </v-modal-footer>
</template>

<script setup lang="ts">
const step = inject("step") as Ref<number>

const isValid = ref(false)
</script>
