<template>
  <v-modal>
    <v-modal-heading class="mb-24">История операций</v-modal-heading>

    <div class="flex flex-col gap-16">
      <div
        v-for="operation of operations"
        :key="operation.id"
        class="rounded-20 border border-e6e7ec p-20 shadow-md shadow-black/5"
      >
        <p>#{{ operation.id }}, {{ new Date(+operation.added_at * 1000).toLocaleString() }}</p>
        <p class="mt-4 text-18 font-bold">{{ operation.type === "buy" ? "Покупка" : "Продажа" }}</p>
        <p>{{ instruments[operation.trade_instrument_id]?.name }}</p>
        <p>Количество штук: {{ +operation.quantity }}</p>
        <p>Сумма: {{ +operation.total }} {{ baseInstrument.sign.value }}</p>
        <p v-if="operation.comment">Комментарий: {{ operation.comment }}</p>
      </div>
    </div>
  </v-modal>
</template>

<script setup lang="ts">
import { TradeInstrument } from "~/models/api"

const baseInstrument = useBaseInstrument()

const portfolioStore = usePortfolioStore()
const response = await findPortfolioOperations(portfolioStore.portfolio?.id || "")
const operations = response.data.data || []
operations.sort((a, b) => +a.id - +b.id)

const instrumentsStore = useInstrumentsStore()
const instruments = ref<Record<string, TradeInstrument>>({})
operations.forEach(async (operation) => {
  instruments.value[operation.trade_instrument_id] = await instrumentsStore.getInstrument(operation.trade_instrument_id)
})
</script>
