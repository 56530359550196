<template>
  <v-modal mobile-type="normal">
    <h2 class="mb-16 text-22 font-bold">Отмена подписки</h2>
    <p class="mb-24">Вы уверены, что хотите отменить подписку?</p>
    <div class="flex gap-10 md:flex-col-reverse">
      <v-button
        variant="danger"
        :loading="submitting"
        @click="submit"
      >
        Да, отменить
      </v-button>
      <v-button
        variant="outlined-without-bg"
        @click="cancel"
      >
        Нет, не отменять
      </v-button>
    </div>
  </v-modal>
</template>

<script setup lang="ts">
const options = useModalOptions<"cancel-subscription">()
const modalsStore = useModalsStore()

function cancel() {
  modalsStore.hide()
}

const user = useUserStore()
const submitting = ref(false)

async function submit() {
  if (!user.details.active_purchase_id) {
    alert("Активной подписки не найдено")
    return
  }

  submitting.value = true

  const response = await cancelPayment(user.details.active_purchase_id)

  if (!response.data.success) {
    submitting.value = false
    return
  }

  await user.fetch()
  submitting.value = false

  alert("Ваша подписка была отменена")
  if (options.value.redirectTo) {
    navigateTo(options.value.redirectTo)
  }

  modalsStore.hide()
}
</script>
