<template>
  <div
    class="flex items-center justify-center rounded-12"
    :class="{
      'bg-white': light,
      'h-36 w-36': size === 'md',
      'h-57 w-57': size === 'lg',
      [config.bgClass]: !light,
      [config.textClass]: true,
    }"
  >
    <Icon
      :name="config.icon"
      :size="iconSize"
    />
  </div>
</template>

<script setup lang="ts">
import { InstrumentType } from "~/models/api"

const props = withDefaults(
  defineProps<{
    typeId?: string
    light?: boolean
    size?: "md" | "lg"
  }>(),
  {
    size: "md",
  }
)

const iconSize = computed(() => {
  switch (props.size) {
    case "md":
      return "18px"
    case "lg":
      return "24px"
  }
})

const config = computed(() => {
  switch (props.typeId) {
    case InstrumentType.Stocks:
      return {
        icon: "iconoir:graph-up",
        textClass: "text-25238b",
        bgClass: "bg-dee6ff",
      }
    case InstrumentType.Funds:
      return {
        icon: "iconoir:shopping-bag",
        textClass: "text-232335",
        bgClass: "bg-e3fdff",
      }
    case InstrumentType.Bonds:
      return {
        icon: "iconoir:journal",
        textClass: "text-232335",
        bgClass: "bg-f0f1f4",
      }
    case InstrumentType.Currency:
      return {
        icon: "iconoir:lot-of-cash",
        textClass: "text-004915",
        bgClass: "bg-d4f9d3",
      }
    case InstrumentType.Crypto:
      return {
        icon: "iconoir:bitcoin-circle",
        textClass: "text-470d5b",
        bgClass: "bg-f3e3ff",
      }
    case InstrumentType.Goods:
      return {
        icon: "iconoir:commodity",
        textClass: "text-4a3f18",
        bgClass: "bg-fafbca",
      }
    case InstrumentType.RealEstate:
      return {
        icon: "iconoir:building",
        textClass: "text-232335",
        bgClass: "bg-ffe5e3",
      }
  }

  return {
    icon: "",
    textClass: "",
    bgClass: "bg-f0f1f4",
  }
})
</script>

<style lang="scss" scoped>
:deep(path) {
  stroke-width: 2px;
}
</style>
