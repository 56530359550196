<template>
  <div class="flex flex-wrap gap-14 md:gap-10">
    <button
      v-for="option of options"
      :key="option.label"
      class="rounded-16 border px-18 py-14 font-medium"
      :class="{
        'border-e6e7ec': option.value !== value,
        'border-2c58f3 text-2c58f3': option.value === value,
      }"
      @click="value = option.value"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<script setup lang="ts" generic="T">
const props = defineProps<{
  modelValue: T
  options: {
    label: string
    value: T
  }[]
}>()

const emit = defineEmits<{
  "update:modelValue": [value: T]
}>()

const value = computed({
  set(newValue: T) {
    emit("update:modelValue", newValue)
  },
  get() {
    return props.modelValue
  },
})
</script>
