<template>
  <div
    class="overflow-hidden rounded-12 border border-e1e1e9"
    :class="{
      'cursor-not-allowed bg-f0f1f4': props.disabled,
      'bg-white': !props.disabled,
      'outline outline-2 -outline-offset-1 outline-2c58f3': focused,
    }"
  >
    <header
      v-if="hasHeader"
      class="flex gap-16 rounded-12 px-14 py-6"
      :class="{
        'bg-f0f1f4': props.disabled,
        'cursor-text bg-white': !props.disabled,
      }"
      @click="focused = true"
    >
      <span
        v-if="props.placeholder"
        class="overflow-hidden text-ellipsis text-11 font-medium text-84849b"
      >
        {{ props.placeholder }}
      </span>

      <span
        v-if="props.maxlength"
        class="ml-auto whitespace-nowrap text-11 font-medium text-c7c6cd"
      >
        {{ value.length }} / {{ props.maxlength }}
      </span>
    </header>

    <textarea
      ref="textarea"
      v-model="value"
      class="relative block w-full resize-none bg-transparent px-14 pb-8 focus:outline-none"
      :class="{
        'cursor-not-allowed opacity-100 [-webkit-text-fill-color:#232335]': props.disabled,
        '-top-4': hasHeader,
        'pt-8': !hasHeader,
      }"
      :disabled="props.disabled"
      :rows="props.rows"
    ></textarea>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue?: null | string
  placeholder?: string
  maxlength?: number
  disabled?: boolean
  rows?: number
}>()

const emit = defineEmits<{
  "update:modelValue": [value: string]
}>()

const value = computed({
  get() {
    return props.modelValue || ""
  },
  set(newValue: string) {
    emit("update:modelValue", newValue)
  },
})

watch(value, () => {
  if (props.maxlength && value.value.length > props.maxlength) {
    value.value = value.value.slice(0, props.maxlength)
  }
})

const hasHeader = computed(() => props.placeholder || props.maxlength)

const textarea = ref<HTMLTextAreaElement>()
const { focused } = useFocus(textarea)
</script>
