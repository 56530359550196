<template>
  <button class="flex h-24 w-24 items-center justify-center rounded-full bg-f0f1f4 text-2c58f3">
    <Icon
      name="tabler:arrow-left"
      size="16px"
    />
  </button>
</template>

<script setup lang="ts"></script>
