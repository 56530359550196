<template>
  <v-modal ref="modal">
    <v-stepper
      v-if="step > 1 && steps > 1"
      :step="step - 1"
      :steps="steps"
      :back-button="step > 1"
      class="mb-24"
      @back="step--"
    />

    <modal-create-portfolio-step-choose-type
      v-if="step === 1"
      v-model="type"
      @next="step++"
    />

    <template v-else-if="step === 2">
      <modal-create-portfolio-step-first-with-assets
        v-if="type === 'with-assets'"
        @next="step++"
      />

      <modal-create-portfolio-step-first-without-assets
        v-else-if="type === 'without-assets'"
        @next="step++"
      />
    </template>

    <modal-create-portfolio-step-distribute-assets
      v-else-if="step === 3 && type === 'without-assets'"
      @next="step++"
    />

    <modal-create-portfolio-step-goal v-else />
  </v-modal>
</template>

<script setup lang="ts">
import { Currency, InvestmentFrequency } from "~/models/api"

const instrumentsStore = useInstrumentsStore()
await instrumentsStore.getTypes()

const type = ref<"with-assets" | "without-assets">()

const modal = ref()
const { step } = useModalStep(modal)

const emoji = ref("💰")
const name = ref("")
const baseInstrumentId = ref(Currency.RUB)
const desiredInstruments = ref<string[]>([])
const distributionByTypes = ref<Record<string, number>>({})
const distributionByInstruments = ref<Record<string, number>>({})
const targetBalance = ref<number>()
const recurringInvestmentAmount = ref<number>()
const investmentFrequency = ref<InvestmentFrequency>(InvestmentFrequency.monthly)

watch(
  [type, desiredInstruments],
  () => {
    distributionByTypes.value = {}
    distributionByInstruments.value = {}
  },
  {
    deep: true,
  }
)

provide("emoji", emoji)
provide("name", name)
provide("baseInstrumentId", baseInstrumentId)
provide("step", step)
provide("desiredInstruments", desiredInstruments)
provide("distributionByTypes", distributionByTypes)
provide("distributionByInstruments", distributionByInstruments)
provide("targetBalance", targetBalance)
provide("recurringInvestmentAmount", recurringInvestmentAmount)
provide("investmentFrequency", investmentFrequency)

const steps = computed(() => {
  switch (type.value) {
    case "without-assets":
      return 3
    case "with-assets":
    default:
      return 1
  }
})
</script>
