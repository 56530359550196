<template>
  <footer class="modal-footer flex justify-between gap-16 md:flex-row-reverse">
    <slot />
  </footer>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.modal-footer {
  @include md {
    :slotted(> *) {
      flex: 1 0 0;
    }
  }
}
</style>
