<template>
  <div class="flex flex-col gap-16">
    <div
      v-for="(asset, idx) in assets"
      :key="asset"
      class="flex gap-16 md:gap-10"
    >
      <v-asset-select
        :model-value="asset"
        class="w-0 flex-1"
        hide-lot-size
        @update:model-value="assets[idx] = $event || ''"
      />

      <button
        class="text-f83d3d"
        @click="removeAsset(idx)"
      >
        <Icon
          name="tabler:trash"
          size="24px"
        />
      </button>
    </div>

    <v-button
      variant="secondary"
      icon="iconoir:plus"
      @click="addAsset"
    >
      Добавить актив
    </v-button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  empty?: boolean
}>()

const assets = inject("desiredInstruments") as Ref<string[]>
if (!props.empty && !assets.value.length) {
  addAsset()
}

function addAsset() {
  assets.value.push("")
}

function removeAsset(idx: number) {
  assets.value.splice(idx, 1)
}
</script>
