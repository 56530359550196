<template>
  <v-modal>
    <v-modal-heading class="mb-24">Добавить актив</v-modal-heading>

    <div class="flex flex-wrap gap-16 md:gap-10">
      <v-asset-select
        v-model="asset"
        class="w-full"
      />

      <v-number-input
        v-model="quantity"
        class="flex-1 md:basis-full"
        placeholder="Количество штук"
        :maximum-fraction-digits="10"
        required
      />

      <v-base-instrument-number-input
        v-model="averagePrice"
        class="flex-1 md:basis-full"
        placeholder="Средняя цена покупки 1 шт."
        required
      />
    </div>

    <v-modal-footer class="mt-24">
      <v-button
        :loading="isPending"
        :disabled="isDisabled"
        @click="submit"
      >
        Добавить
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const asset = ref<string | null>(null)
const quantity = ref()
const averagePrice = ref()

const isDisabled = computed(() => {
  return !quantity.value || !averagePrice.value || !asset.value
})
const isPending = ref(false)

async function submit() {
  if (!asset.value) {
    return
  }

  isPending.value = true

  try {
    const portfolioStore = usePortfolioStore()

    const response = await editPortfolio({
      id: portfolioStore.portfolio?.id,
      buy: [
        {
          id: asset.value,
          quantity: quantity.value,
          total: averagePrice.value * quantity.value,
        },
      ],
    })

    if (response.data.success) {
      await portfolioStore.updatePortfolio()

      const modalsStore = useModalsStore()
      modalsStore.hide()
    }
  } finally {
    isPending.value = false
  }
}
</script>
