<template>
  <v-modal-heading class="mb-24">Добавьте желаемые активы</v-modal-heading>
  <p
    v-if="canSkip"
    class="rounded-12 bg-f0f1f4 p-12 text-15 text-424250"
  >
    Вы можете пропустить этот шаг, если вам не нужны новые активы в портфеле.
  </p>

  <modal-shared-desired-assets
    class="mt-16"
    :empty="canSkip"
  />

  <v-modal-footer class="mt-24">
    <v-button
      :disabled="!canContinue"
      @click="step++"
    >
      Продолжить
    </v-button>
  </v-modal-footer>
</template>

<script setup lang="ts">
const step = inject("step") as Ref<number>

const portfolioStore = usePortfolioStore()

const desiredInstruments = inject("desiredInstruments") as Ref<string[]>

const canSkip = computed(() => Boolean(portfolioStore.portfolio?.instruments.length))
const canContinue = computed(() => {
  return desiredInstruments.value.every((id) => id) && (canSkip.value || desiredInstruments.value.length)
})
</script>
