const breakpoints = {
  xl: 1399,
  lg: 1199,
  md: 991,
  sm: 767,
  xs: 575,
}

type Screen = ComputedRef<{
  [key in keyof typeof breakpoints]: boolean
}>

export function useScreen() {
  const { width } = useWindowSize()

  return computed(() => {
    return Object.fromEntries(Object.entries(breakpoints).map(([key, maxWidth]) => [key, width.value <= maxWidth]))
  }) as Screen
}
