<template>
  <v-modal mobile-type="normal">
    <h2 class="mb-16 text-22 font-bold">Блокировка пользователя</h2>
    <v-input
      v-model="reason"
      placeholder="Причина блокировка"
    />
    <v-modal-footer class="mt-16 !justify-start">
      <v-button
        variant="danger"
        :disabled="!reason"
        :loading="submitting"
        @click="submit"
      >
        Заблокировать
      </v-button>
      <v-button
        variant="outlined-without-bg"
        @click="cancel"
      >
        Отменить
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const eventBus = useEventBus()
const options = useModalOptions<"ban-user">()
const modalsStore = useModalsStore()
const reason = ref("")

function cancel() {
  modalsStore.hide()
}

const submitting = ref(false)
async function submit() {
  submitting.value = true

  const response = await setUserBanReason({ id: options.value.id, banReason: reason.value })

  if (!response.data.success) {
    submitting.value = false
    return
  }

  eventBus.emit("userBanned")
  modalsStore.hide()
}
</script>
