<template>
  <v-bottom-sheet
    v-if="isMobile"
    v-model="isVisible"
    :full-height="bottomSheetProps?.fullHeight"
    v-bind="bottomSheetProps"
    @scroll="$emit('blurSearchInput')"
    @move="$emit('blurSearchInput')"
    @after-enter="$emit('tryFocusOnSearchInput')"
  >
    <slot />
  </v-bottom-sheet>
  <v-select-dropdown-popper
    v-else
    :visible="isVisible"
    v-bind="popperProps"
  >
    <slot />
  </v-select-dropdown-popper>
</template>

<script setup lang="ts">
import { VBottomSheetProps } from "../v-bottom-sheet/v-bottom-sheet.vue"
import { VSelectDropdownPopperProps } from "./v-select-dropdown-popper.vue"

const props = defineProps<{
  visible?: boolean
  bottomSheetProps?: Partial<VBottomSheetProps>
  popperProps?: Partial<VSelectDropdownPopperProps>
}>()

const emit = defineEmits<{
  "update:visible": [isVisible: boolean]
  blurSearchInput: []
  tryFocusOnSearchInput: []
}>()

const isVisible = computed({
  get() {
    return props.visible
  },
  set(newValue: boolean) {
    emit("update:visible", newValue)
  },
})

const screen = useScreen()
const isMobile = computed(() => screen.value.md)

watchEffect(() => {
  if (!isMobile.value && isVisible.value) {
    emit("tryFocusOnSearchInput")
  }
})
</script>
