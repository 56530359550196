<template>
  <div
    ref="wrapper"
    class="relative overflow-hidden rounded-16"
    :style="{
      height: height + 'px',
    }"
  >
    <div
      v-if="cover && isCoverVisible"
      class="absolute inset-0 z-10 cursor-pointer"
      @click="play"
    >
      <img
        class="absolute inset-0 h-full w-full object-cover"
        :src="cover"
      />
      <div class="absolute inset-0 bg-232335/30"></div>
      <button class="absolute inset-0 m-auto flex h-55 w-55 items-center justify-center rounded-10 bg-white/40 text-white">
        <Icon
          name="material-symbols:play-arrow-rounded"
          size="50px"
        />
      </button>
    </div>
    <youtube
      ref="video"
      :src="props.src"
      width="100%"
      height="100%"
      class="absolute inset-0 h-full w-full"
      @ready="onReady"
    />
  </div>
</template>

<script setup lang="ts">
import Youtube from "vue3-youtube"

const props = defineProps<{
  src: string
  cover?: string
}>()

const { getImage } = useAssets()

const cover = computed(() => (props.cover ? getImage(props.cover) : undefined))
const isCoverVisible = ref(true)

type Player = {
  playVideo: () => void
}

const video = ref<HTMLElement & Player>()
const triedToPlay = ref(false)
const isReady = ref(false)

function play() {
  triedToPlay.value = true
  if (!isReady.value) {
    return
  }

  isCoverVisible.value = false
  video.value?.playVideo()
}

function onReady() {
  isReady.value = true
  if (triedToPlay.value) {
    play()
  }
}

const wrapper = ref<HTMLElement>()
const { width } = useElementSize(wrapper)
const aspectRatio = 16 / 9
const height = computed(() => width.value / aspectRatio)
</script>
