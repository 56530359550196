<template>
  <button
    class="relative flex w-full whitespace-nowrap rounded-12 border py-6 pl-8 pr-33 text-left transition-all"
    :class="{
      'border-e1e1e9': !$props.dropdownVisible,
      'border-c7c6cd': $props.dropdownVisible,
      'cursor-not-allowed bg-f0f1f4': $props.disabled,
      'cursor-progress': $props.loading,
      'bg-white': !$props.disabled,
    }"
    @click="onClick"
  >
    <div
      v-if="$slots.icon || $props.icons"
      class="mr-4"
    >
      <slot name="icon">
        <v-field-icon
          v-if="$props.icons"
          :path="modelValue?.iconPath"
          :name="modelValue?.iconName"
        />
      </slot>
    </div>

    <div
      v-if="!$props.hideLabel"
      class="relative min-w-0 grow pl-6"
      :class="{
        'pt-14': $props.placeholder,
      }"
    >
      <span
        class="block max-w-full overflow-hidden text-ellipsis"
        :class="{ 'select-none text-transparent': !modelValue?.label }"
      >
        {{ modelValue?.label || "~" }}

        <span
          v-if="modelValue?.secondaryLabel"
          class="text-14 font-normal text-84849b"
        >
          {{ modelValue.secondaryLabel }}
        </span>
      </span>

      <span
        class="pointer-events-none absolute left-6 block h-fit w-full overflow-hidden text-ellipsis font-medium text-84849b transition-all"
        :class="{
          'top-1 text-11': isPlaceholderStacked,
          'top-1/2 -translate-y-1/2 pb-2': !isPlaceholderStacked,
        }"
      >
        {{ $props.placeholder }}
        <span
          v-if="$props.required"
          class="text-f83d3d"
        >
          *
        </span>
      </span>
    </div>

    <Transition>
      <v-spinner
        v-if="loading"
        class="!absolute bottom-0 right-10 top-0 !m-auto border-black/30 border-l-black"
        size="16px"
        thickness="2px"
      />
      <Icon
        v-else
        name="iconoir:nav-arrow-down"
        class="absolute bottom-0 right-10 top-0 m-auto transition-all"
        :class="{
          'rotate-180': $props.dropdownVisible,
        }"
        size="18px"
      />
    </Transition>
  </button>
</template>

<script setup lang="ts">
import { SelectOption } from "./v-select.vue"

const props = defineProps<{
  modelValue: null | SelectOption
  dropdownVisible: boolean
  placeholder?: string
  icons?: boolean
  required?: boolean
  disabled?: boolean
  hideLabel?: boolean
  loading?: boolean
}>()

const emit = defineEmits<{
  "update:modelValue": [value: SelectOption]
  "update:dropdownVisible": [value: boolean]
}>()

const isPlaceholderStacked = computed(() => {
  return props.modelValue !== null
})

const isDropdownVisible = computed({
  get() {
    if (props.disabled) {
      return false
    }

    return props.dropdownVisible
  },
  set(newValue: boolean) {
    if (props.disabled) {
      return
    }

    emit("update:dropdownVisible", newValue)
  },
})

function onClick() {
  if (props.loading) {
    return
  }

  isDropdownVisible.value = !isDropdownVisible.value
}
</script>
