<template>
  <div>
    <v-modal-heading class="mb-24">Подтвердите план изменений</v-modal-heading>
    <p class="mb-4">Вы собираетесь сделать изменения на сумму:</p>
    <p class="mb-16 text-26 font-bold">{{ formatMoney(changesSum) }} {{ baseInstrument.sign.value }}</p>

    <ul class="flex flex-col gap-12 font-medium">
      <li
        v-if="suggestion?.sell?.length"
        class="flex items-center gap-4"
      >
        <Icon
          name="iconoir:check"
          size="24px"
          class="text-13a906"
        />
        Учитывая продажи
      </li>
      <li class="flex items-center gap-4">
        <Icon
          name="iconoir:check"
          size="24px"
          class="text-13a906"
        />
        Учитывая примененный баланс “Деньги”
      </li>
    </ul>

    <!-- <div class="my-24 rounded-12 bg-f0f1f4 px-16 py-12">
      Вы можете отказаться от некоторых предлагаемых пунктов и мы пересчитаем список изменений в пользу других активов
    </div> -->

    <div class="mt-24 flex flex-col gap-16">
      <div
        v-for="key of visibleSuggestionKeys"
        :key="key"
      >
        <div class="mb-16 flex items-start justify-between gap-8 text-right">
          <h3 class="text-18 font-bold">{{ dict[key].title }}</h3>
          <p class="text-14 font-medium text-84849b">
            {{ dict[key].sum }}:
            <span class="text-232335">{{ formatMoney(sums[key] || 0) }} {{ baseInstrument.sign.value }}</span>
          </p>
        </div>

        <div class="flex flex-col gap-16 md:gap-20">
          <div
            v-for="instr of visibleSuggestion[key]"
            :key="instr.instrument.id"
            class="grid grid-cols-[1fr_125px_150px_150px] gap-16 md:grid-cols-[1fr_0.7fr] md:gap-10"
          >
            <v-input
              :placeholder="'Актив' + (instr.instrument.lotsize ? ` (мин. ${formatLotSize(instr.instrument.lotsize)} шт.)` : '')"
              :model-value="instr.instrument.secid || instr.instrument.isin"
              :class="{
                'opacity-30': rejectedSuggestions.includes(instr.instrument.id),
              }"
              disabled
            >
              <template #icon>
                <v-asset-icon
                  :type-id="instr.instrument.trade0instrument0type_id"
                  light
                />
              </template>
            </v-input>
            <v-input
              placeholder="Кол-во штук"
              :model-value="formatQuantity(instr.quantity)"
              :class="{
                'opacity-30': rejectedSuggestions.includes(instr.instrument.id),
              }"
              disabled
            />
            <v-base-instrument-number-input
              :model-value="instr.price"
              placeholder="Сумма"
              disabled
              :class="{
                'opacity-30': rejectedSuggestions.includes(instr.instrument.id),
              }"
            />
            <v-button
              v-if="!rejectedSuggestions.includes(instr.instrument.id)"
              variant="outlined-without-bg"
              icon="iconoir:prohibition"
              class="shrink-0 !px-10 !text-f83d3d hover:!bg-transparent"
              @click="rejectedSuggestions.push(instr.instrument.id)"
            >
              Отказаться
            </v-button>
            <v-button
              v-else
              variant="outlined-without-bg"
              icon="iconoir:plus"
              class="shrink-0 !px-10 !text-13a906 hover:!bg-transparent"
              @click="removeFromRejected(instr.instrument.id)"
            >
              Добавить
            </v-button>
          </div>
        </div>
      </div>
    </div>

    <v-modal-footer class="mt-24">
      <v-button
        :disabled="!canContinue"
        @click="step++"
      >
        Подтвердить и продолжить
      </v-button>
    </v-modal-footer>
  </div>
</template>

<script setup lang="ts">
import { RebalanceSuggestionView } from "./index.vue"

const baseInstrument = useBaseInstrument()

const step = inject("step") as Ref<number>
const balance = inject("balance") as Ref<number>
const additionalMoney = inject("additionalMoney") as Ref<number>
const suggestion = inject("suggestion") as Ref<RebalanceSuggestionView | undefined>
const rejectedSuggestions = inject("rejectedSuggestions") as Ref<string[]>

const visibleSuggestion = computed<RebalanceSuggestionView>(() => {
  if (!suggestion.value) {
    return {}
  }

  return Object.fromEntries(
    Object.entries(suggestion.value)
      .map(([key, entry]) => [key, entry.filter((instr) => instr.instrument.id !== baseInstrument.id.value)])
      .filter(([, entry]) => entry.length)
  )
})
const visibleSuggestionKeys = computed(() => {
  const keys = ["sell", "buy"] satisfies (keyof typeof visibleSuggestion.value)[]
  return keys.filter((key) => key in visibleSuggestion.value)
})

const canContinue = computed(() => {
  const areSuggestionsLeft = Object.values(visibleSuggestion.value)
    .flat()
    .some(({ instrument }) => !rejectedSuggestions.value.includes(instrument.id))

  return areSuggestionsLeft
})

const sums = computed(() => {
  const calculated = Object.fromEntries(
    Object.entries(visibleSuggestion.value).map(([key, instrs]) => [
      key,
      instrs.filter((instr) => !rejectedSuggestions.value.includes(instr.instrument.id)).reduce((sum, instr) => sum + instr.price, 0),
    ])
  )

  return {
    buy: 0,
    sell: 0,
    ...calculated,
  }
})

const changesSum = computed(() => {
  if (!sums.value.sell) {
    return sums.value.buy
  }

  if (!sums.value.buy) {
    return sums.value.sell
  }

  return balance.value + additionalMoney.value + sums.value.sell - sums.value.buy
})

const dict = {
  buy: {
    title: "Купить",
    sum: "Общая сумма покупки составит",
  },
  sell: {
    title: "Продать",
    sum: "Общая сумма продажи составит",
  },
}

function removeFromRejected(id: string) {
  const idx = rejectedSuggestions.value.indexOf(id)
  rejectedSuggestions.value.splice(idx, 1)
}
</script>
