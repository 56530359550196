<template>
  <div>
    <v-modal-heading class="mb-36 md:mb-24">Задайте финансы</v-modal-heading>

    <div class="md:rounded-14 mb-36 rounded-24 bg-f0f1f4 px-24 py-16 md:mb-24 md:px-10">
      <p class="mb-4">Ваш текущий баланс актива “Деньги”:</p>
      <p class="text-26 font-bold">{{ formatMoney(balance) }} {{ baseInstrument.sign.value }}</p>
      <div class="mt-14 flex items-center gap-10">
        <v-toggle v-model="useBalanceMoney" />
        <span>Использовать в плане изменений</span>
      </div>
    </div>

    <h3 class="mb-8 text-18 font-bold">Какую сумму хотите внести дополнительно?</h3>
    <p class="mb-8">Введите сумму, которую вы бы могли внести дополнительно для изменений в вашем портфеле:</p>
    <v-base-instrument-number-input
      v-model="additionalMoney"
      placeholder="Введите сумму"
      required
    />

    <h3 class="mb-8 mt-24 text-18 font-bold">Хотите ли вы сделать ребалансировку активов?</h3>
    <p class="mb-8">Включив режим “Только покупка”, мы не будем учитывать продажу ваших текущих активов</p>
    <div class="mt-8 flex items-center gap-10">
      <v-toggle v-model="doNotSell" />
      <span class="font-bold">Только покупка</span>
    </div>

    <!-- <h3 class="mb-8 mt-36 text-18 font-bold">Как изменится прогресс?</h3>
    <p class="mb-8">Вы станете ближе к достижению вашей финальной цели на:</p>
    <p class="mb-36 text-36 font-bold text-2c58f3">+?%</p> -->

    <v-modal-footer class="mt-36 md:mt-24">
      <v-button
        :loading="submitting"
        @click="submit"
      >
        Подтвердить и продолжить
      </v-button>
    </v-modal-footer>
  </div>
</template>

<script setup lang="ts">
import { RebalanceStrategy, RebalanceSuggestion } from "~/models/api"

const baseInstrument = useBaseInstrument()

const portfolioStore = usePortfolioStore()

const step = inject("step") as Ref<number>
const originalSuggestion = inject("originalSuggestion") as Ref<RebalanceSuggestion["suggestions"]>
const balance = inject("balance") as Ref<number>
const additionalMoney = inject("additionalMoney") as Ref<number>
const useBalanceMoney = inject("useBalanceMoney") as Ref<boolean>
const doNotSell = inject("doNotSell") as Ref<boolean>

const submitting = ref(false)
async function submit() {
  if (!portfolioStore.portfolio) {
    return
  }

  submitting.value = true

  const responses = await Promise.all(
    Object.values(RebalanceStrategy).map((strategy) =>
      suggestRebalance({
        id: portfolioStore.portfolio?.id as string,
        additionalMoney: additionalMoney.value,
        useBalance: useBalanceMoney.value,
        doNotSell: doNotSell.value,
        strategy,
        silent: true,
      })
    )
  )

  submitting.value = false

  const successfulResponses = responses.filter((resp) => resp.data.success)
  successfulResponses.sort(
    (responseA, responseB) => responseA.data.data.result_base_instrument_equity - responseB.data.data.result_base_instrument_equity
  )
  const response = successfulResponses[0]

  if (!response) {
    const errors = new Set(
      responses
        .filter((resp) => !resp.data.success)
        .map((resp) => resp.data.errors)
        .flat()
    )

    errors.forEach((error) => {
      alert(translateError(error))
    })

    return
  }

  if (!response.data.data.suggestions.buy.length && !response.data.data.suggestions.sell.length) {
    alert("Предложений для изменения портфеля не найдено")
    return
  }

  originalSuggestion.value = response.data.data.suggestions
  step.value++
}
</script>
