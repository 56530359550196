<template>
  <v-modal mobile-type="normal">
    <h2 class="mb-16 text-22 font-bold">Удаление страницы</h2>
    <p class="mb-24">Вы уверены, что хотите удалить страницу?</p>
    <v-modal-footer class="!justify-start">
      <v-button
        variant="danger"
        :loading="submitting"
        @click="submit"
      >
        Да, удалить
      </v-button>
      <v-button
        variant="outlined-without-bg"
        @click="cancel"
      >
        Нет, отменить
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const eventBus = useEventBus()
const options = useModalOptions<"delete-page">()
const modalsStore = useModalsStore()

function cancel() {
  modalsStore.hide()
}

const submitting = ref(false)
async function submit() {
  submitting.value = true

  const response = await deletePage({ id: options.value.id })

  if (!response.data.success) {
    submitting.value = false
    return
  }

  eventBus.emit("pageDeleted")
  modalsStore.hide()
}
</script>
