import { User } from "~/models/api"

export function getUser({ silent }: { silent?: boolean } = {}) {
  return get<User>("/user/getData/", { silent })
}

export function deleteUser({ id }: { id?: string } = {}) {
  return post("/user/delete/", { id })
}

export function setUserBanReason({ id, banReason }: { id: string; banReason?: string }) {
  return post("/user/setBanReason/", { id, ban_reason: banReason })
}

export function getUsers() {
  return get<User[]>("/user/find/")
}
