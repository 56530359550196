<template>
  <modal-shared-portfolio-goal />

  <v-modal-footer class="mt-24">
    <v-button
      :disabled="!isValid"
      :loading="submitting"
      @click="submit"
    >
      Создать портфель и стратегию
    </v-button>
  </v-modal-footer>
</template>

<script setup lang="ts">
import { InvestmentFrequency, Currency } from "~/models/api"

const baseInstrumentId = inject("baseInstrumentId") as Ref<Currency>

const instrumentsStore = useInstrumentsStore()

const desiredInstruments = inject("desiredInstruments") as Ref<string[]>
const distributionByTypes = inject("distributionByTypes") as Ref<Record<string, number>>
const distributionByInstruments = inject("distributionByInstruments") as Ref<Record<string, number>>
const targetBalance = inject("targetBalance") as Ref<number>
const recurringInvestmentAmount = inject("recurringInvestmentAmount") as Ref<number>
const investmentFrequency = inject("investmentFrequency") as Ref<InvestmentFrequency>

const usedInstrumentsIds = Array.from(new Set([...desiredInstruments.value]))
const usedInstruments = await Promise.all(usedInstrumentsIds.map((id) => instrumentsStore.getInstrument(id)))

const usedInstrumentsTypesIds = Array.from(new Set(usedInstruments.map((instr) => instr.trade0instrument0type_id)))

const name = inject("name") as Ref<string>
const emoji = inject("emoji") as Ref<string>

const submitting = ref(false)

const isValid = computed(() => targetBalance.value && recurringInvestmentAmount.value && investmentFrequency.value)

async function submit() {
  submitting.value = true

  const desiredInstruments = usedInstrumentsIds
    .map((id) => ({ id, targetPercents: distributionByInstruments.value[id] }))
    .filter(({ targetPercents }) => targetPercents != undefined)

  if (!desiredInstruments.find(({ id }) => id === baseInstrumentId.value)) {
    desiredInstruments.push({
      id: baseInstrumentId.value,
      targetPercents: 0,
    })
  }

  const response = await editPortfolio({
    name: name.value,
    emoji: emoji.value,
    baseInstrumentId: baseInstrumentId.value,
    desiredInstruments,
    typesDistribution: usedInstrumentsTypesIds
      .map((id) => ({ id, targetPercents: distributionByTypes.value[id] }))
      .filter(({ targetPercents }) => targetPercents != undefined),
    ignoreLotSize: true,
    targetBalance: targetBalance.value,
    recurringInvestmentAmount: recurringInvestmentAmount.value,
    investmentFrequency: investmentFrequency.value,
  })

  submitting.value = false

  if (!response.data.success) {
    return
  }

  const modalsStore = useModalsStore()
  modalsStore.hide()

  navigateTo(`/portfolio/${response.data.data.id}`)
}
</script>
