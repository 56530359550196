<template>
  <div class="relative">
    <input
      ref="input"
      v-model="value"
      :placeholder="placeholder"
      class="placeholder w-full rounded-8 border border-e1e1e9 bg-white py-8 pl-14 placeholder:font-medium placeholder:text-ababab"
      :class="{
        'pr-14': $props.icon,
        'pr-30': $props.icon,
        'outline outline-2 -outline-offset-1 outline-2c58f3': focused,
      }"
    />

    <Transition>
      <div
        v-if="$props.loading"
        class="absolute inset-y-0 right-10 m-auto h-16"
      >
        <v-spinner
          class="border-black/30 border-l-black/60"
          size="16px"
          thickness="2px"
        />
      </div>
      <Icon
        v-else-if="$props.icon"
        class="absolute inset-y-0 right-8 m-auto text-18 text-black/60"
        :name="$props.icon"
      />
    </Transition>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue?: string
  placeholder?: string
  icon?: string
  loading?: boolean
}>()

const emit = defineEmits<{
  "update:modelValue": [value: string]
}>()

const localValue = ref("")
const value = computed({
  get() {
    return props.modelValue || localValue.value
  },
  set(newValue: string) {
    localValue.value = newValue
    emit("update:modelValue", newValue)
  },
})

const input = ref<HTMLInputElement>()
const { focused } = useFocus(input)

function focus() {
  focused.value = true
}

function blur() {
  focused.value = false
}

defineExpose({
  focus,
  blur,
})
</script>
