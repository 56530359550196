import { Page } from "~/models/api"

export function getPages(params: { id?: string } = {}) {
  return get<Page[]>("pages/get/", { params })
}

export function editPage(data: { id?: string; sort: string; content: string; uri: string; description: string; title: string }) {
  return post<Page>("pages/edit/", data)
}

export function deletePage(data: { id: string }) {
  return post("pages/delete/", data)
}
