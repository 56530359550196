<template>
  <v-modal-heading class="mb-24 flex items-center gap-16 md:gap-8">
    <v-back
      class="relative top-2"
      @click="step--"
    />
    Создание портфеля
  </v-modal-heading>

  <modal-shared-portfolio-name class="mb-24" />

  <h2 class="mb-16 text-18 font-bold">Добавьте активы</h2>
  <modal-shared-base-instrument-select class="mb-16" />

  <p class="mb-16 rounded-12 bg-f0f1f4 px-16 py-12 text-424250">
    Вам не нужно считать сумму самостоятельно. Вы можете внести информацию от брокеров:
  </p>

  <div class="flex flex-col gap-16">
    <div
      v-for="(asset, idx) in assets"
      :key="asset.id || idx"
      class="flex gap-16 md:flex-wrap md:gap-10"
    >
      <v-asset-select
        v-model="asset.id"
        class="w-0 flex-1 md:basis-full"
      />

      <v-number-input
        v-model="asset.quantity"
        class="basis-[150px] md:basis-[100%]"
        placeholder="Количество штук"
        :maximum-fraction-digits="10"
        required
        stack-placeholder
      />

      <v-base-instrument-number-input
        v-model="asset.averagePrice"
        :base-instrument-id="baseInstrumentId"
        class="basis-[185px] md:flex-1"
        placeholder="Средняя цена покупки 1 шт."
        required
        stack-placeholder
      />

      <button
        class="text-f83d3d"
        @click="removeAsset(idx)"
      >
        <Icon
          name="tabler:trash"
          size="24px"
        />
      </button>
    </div>

    <v-button
      variant="secondary"
      icon="iconoir:plus"
      @click="addAsset"
    >
      Добавить актив
    </v-button>
  </div>

  <v-modal-footer>
    <v-button
      class="mt-24"
      :disabled="!isValid"
      :loading="submitting"
      @click="submit"
    >
      Создать портфель
    </v-button>
  </v-modal-footer>
</template>

<script setup lang="ts">
import { Currency, OwnedTradeInstrumentInput } from "~/models/api"
import { Nullable } from "~/models/utilities"
import { clone } from "lodash-es"

defineEmits<{
  next: []
}>()

const baseInstrumentId = inject("baseInstrumentId") as Ref<Currency>

const step = inject("step") as Ref<number>
const name = inject("name") as Ref<string>
const emoji = inject("emoji") as Ref<string>

const assets = ref<Nullable<{ id: string; averagePrice: number; quantity: number }>[]>([])
if (!assets.value.length) {
  addAsset()
}

function addAsset() {
  assets.value.push({
    id: null,
    averagePrice: null,
    quantity: null,
  })
}

function removeAsset(idx: number) {
  assets.value.splice(idx, 1)
}

const isValid = computed(
  () => name.value && emoji.value && assets.value.length && assets.value.every((asset) => asset.id && asset.quantity && asset.averagePrice)
)

const submitting = ref(false)

async function submit() {
  if (!isValid.value) {
    return
  }

  submitting.value = true

  const buy = clone(assets.value).map((asset) => ({
    id: asset.id,
    quantity: asset.quantity || 0,
    total: (asset.averagePrice || 0) * (asset.quantity || 0),
  })) as OwnedTradeInstrumentInput[]
  const total = buy.reduce((sum, instr) => sum + instr.total, 0)
  if (total) {
    buy.push({
      id: baseInstrumentId.value,
      quantity: total,
      total: total,
      do_not_add_to_added_equity: 1,
    })
  }

  const response = await editPortfolio({
    name: name.value,
    emoji: emoji.value,
    baseInstrumentId: baseInstrumentId.value,
    buy,
  })

  submitting.value = false

  if (!response.data.success) {
    return
  }

  const modalsStore = useModalsStore()
  modalsStore.hide()

  navigateTo(`/portfolio/${response.data.data.id}`)
}
</script>
