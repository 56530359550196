<template>
  <div
    ref="selectWrapperEl"
    class="relative"
  >
    <v-select-button
      v-model="modelValue"
      v-model:dropdown-visible="isDropdownVisible"
      :options="$props.options"
      :placeholder="$props.placeholder"
      :icons="$props.icons"
      :required="$props.required"
      :hide-label="$props.hideLabel"
      :disabled="disabled"
      :loading="$props.loading"
    >
      <template
        v-if="$slots.icon"
        #icon
      >
        <slot name="icon" />
      </template>
    </v-select-button>

    <v-select-dropdown
      v-model="modelValue"
      v-model:visible="isDropdownVisible"
      v-model:search-query="searchQuery"
      :options="$props.options"
      :icons="$props.icons"
      :placeholder="$props.placeholder"
      :choose-text="$props.chooseText"
      :searchable="$props.searchable"
      :search-autofocus="$props.searchAutofocus"
      :searching="$props.searching"
      :search-placeholder="$props.searchPlaceholder"
      :no-options-text="$props.noOptionsText"
      :styles="$props.styles"
      :bottom-sheet-props="$props.bottomSheetProps"
      :popper-props="$props.popperProps"
    >
      <template #options="{ choose }">
        <slot
          name="options"
          :choose="choose"
        ></slot>
      </template>
    </v-select-dropdown>
  </div>
</template>

<script setup lang="ts" generic="T, U extends SelectOption<T>">
import { VBottomSheetProps } from "../v-bottom-sheet/v-bottom-sheet.vue"
import { VSelectDropdownPopperProps } from "./v-select-dropdown-popper.vue"

export type SelectOption<V = unknown> = {
  label?: string
  secondaryLabel?: string
  iconPath?: string
  iconName?: string
  value: V
}

const props = withDefaults(
  defineProps<{
    modelValue?: null | U
    options: U[]
    placeholder?: string
    icons?: boolean
    required?: boolean
    disabled?: boolean
    loading?: boolean
    hideLabel?: boolean
    searchable?: boolean
    searching?: boolean
    searchPlaceholder?: string
    searchAutofocus?: boolean
    searchQuery?: string
    chooseText?: string
    noOptionsText?: string
    styles?: {
      desktopDropdownWidth?: string
    }
    bottomSheetProps?: Partial<VBottomSheetProps>
    popperProps?: Partial<VSelectDropdownPopperProps>
  }>(),
  {
    modelValue: null,
    searchPlaceholder: "Поиск",
    searchQuery: "",
    noOptionsText: "Опций не найдено",
  }
)

const emit = defineEmits<{
  "update:modelValue": [value: null | U]
  "update:searchQuery": [value: string]
}>()

const modelValue = computed({
  get() {
    return props.modelValue
  },
  set(newValue: null | U) {
    emit("update:modelValue", newValue)
  },
})

const screen = useScreen()
const selectWrapperEl = ref<HTMLDivElement>()
onClickOutside(selectWrapperEl, () => {
  if (!screen.value.md) {
    hideDropdown()
  }
})

const isDropdownVisible = ref(false)

function hideDropdown() {
  isDropdownVisible.value = false
}

const searchQuery = computed({
  get() {
    return props.searchQuery
  },
  set(newValue: string) {
    emit("update:searchQuery", newValue)
  },
})
</script>
