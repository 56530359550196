<template>
  <div>
    <v-modal-heading class="mb-24">Задайте цель портфеля</v-modal-heading>

    <p class="mb-24 text-424250">Укажите размер портфеля, к которому вы стремитесь:</p>

    <v-base-instrument-number-input
      v-model="targetBalance"
      :base-instrument-id="baseInstrumentId"
      class="mb-24"
      placeholder="Общая стоимость"
      required
    />

    <p class="mb-24 text-424250">Введите желаемую сумму ежемесячного/еженедельного взноса в портфель для быстрого достижения цели:</p>

    <div class="mb-24 flex gap-16 md:flex-wrap md:gap-10">
      <v-base-instrument-number-input
        v-model="recurringInvestmentAmount"
        :base-instrument-id="baseInstrumentId"
        class="grow"
        placeholder="Сумма"
        required
      />

      <v-select
        v-model="localFrequency"
        placeholder="Периодичность"
        class="w-0 basis-[160px] md:basis-full"
        :options="frequencies"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Currency, InvestmentFrequency } from "@/models/api"
import { SelectOption } from "~/components/base/v-select/v-select.vue"

const targetBalance = inject("targetBalance") as Ref<number>
const recurringInvestmentAmount = inject("recurringInvestmentAmount") as Ref<number>
const frequency = inject("investmentFrequency") as Ref<InvestmentFrequency>

const injectedBaseInstrumentId = inject("baseInstrumentId") as Ref<Currency> | undefined
const baseInstrument = useBaseInstrument()
const baseInstrumentId = computed(() => {
  return injectedBaseInstrumentId?.value || baseInstrument.id.value
})

const frequencies: SelectOption<InvestmentFrequency>[] = [
  {
    label: "Раз в месяц",
    value: InvestmentFrequency.monthly,
  },
  {
    label: "Раз в неделю",
    value: InvestmentFrequency.weekly,
  },
]
const localFrequency = computed({
  get() {
    return frequencies.find((f) => f.value === frequency.value) as SelectOption<InvestmentFrequency>
  },
  set(option: SelectOption<InvestmentFrequency>) {
    frequency.value = option.value
  },
})
</script>
