<template>
  <component
    :is="component"
    :model-value="Boolean(modals.modal)"
    :class="modalClass"
    @update:model-value="onModelValueUpdate"
  ></component>
</template>

<script setup lang="ts">
const modals = useModalsStore()

const component = computed(() => {
  switch (modals.modal) {
    case "operations-history":
      return resolveComponent("modal-operations-history")
    case "create-portfolio":
      return resolveComponent("modal-create-portfolio")
    case "rename-portfolio":
      return resolveComponent("modal-rename-portfolio")
    case "delete-portfolio":
      return resolveComponent("modal-delete-portfolio")
    case "delete-user":
      return resolveComponent("modal-delete-user")
    case "ban-user":
      return resolveComponent("modal-ban-user")
    case "unban-user":
      return resolveComponent("modal-unban-user")
    case "user-settings":
      return resolveComponent("modal-user-settings")
    case "add-portfolio-changes":
      return resolveComponent("modal-add-portfolio-changes")
    case "portfolio-charts":
      return resolveComponent("modal-portfolio-charts")
    case "operate-asset":
      return resolveComponent("modal-operate-asset")
    case "add-asset":
      return resolveComponent("modal-add-asset")
    case "edit-strategy":
      return resolveComponent("modal-edit-strategy")
    case "add-asset-comment":
      return resolveComponent("modal-add-asset-comment")
    case "delete-instrument-comment":
      return resolveComponent("modal-delete-instrument-comment")
    case "instrument-comments":
      return resolveComponent("modal-instrument-comments")
    case "add-free-access-promo-code":
      return resolveComponent("modal-edit-free-access-promo-code")
    case "edit-free-access-promo-code":
      return resolveComponent("modal-edit-free-access-promo-code")
    case "delete-free-access-promo-code":
      return resolveComponent("modal-delete-free-access-promo-code")
    case "add-discount-promo-code":
      return resolveComponent("modal-edit-discount-promo-code")
    case "edit-discount-promo-code":
      return resolveComponent("modal-edit-discount-promo-code")
    case "delete-discount-promo-code":
      return resolveComponent("modal-delete-discount-promo-code")
    case "delete-page":
      return resolveComponent("modal-delete-page")
    case "subscription":
      return resolveComponent("modal-subscription")
    case "cancel-subscription":
      return resolveComponent("modal-cancel-subscription")
    case "instruction":
      return resolveComponent("modal-instruction")
  }
})

const modalClass = computed(() => {
  switch (modals.modal) {
    case "delete-portfolio":
    case "delete-instrument-comment":
    case "delete-user":
    case "ban-user":
    case "unban-user":
    case "delete-free-access-promo-code":
    case "delete-discount-promo-code":
    case "delete-page":
    case "user-settings":
    case "cancel-subscription":
      return "max-w-[500px]"
    case "add-portfolio-changes":
      return "max-w-[800px]"
    case "subscription":
      return "max-w-[550px]"
    default:
      return "max-w-[740px]"
  }
})

function onModelValueUpdate(newValue: boolean) {
  if (!newValue) {
    modals.modal = null
  }
}
</script>
