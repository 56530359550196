<template>
  <v-modal>
    <v-modal-heading class="mb-24">{{ heading }}</v-modal-heading>

    <v-input
      v-model="data.code"
      placeholder="Промокод"
      class="mb-8"
    />

    <div class="mb-16 flex items-center gap-10">
      <v-toggle v-model="data.isPcre" />
      <span>Регулярное выражение</span>
    </div>

    <v-number-input
      v-model="data.days"
      placeholder="Количество дней бесплатного доступа"
      class="mb-16"
    />

    <v-number-input
      v-model="data.usageLimit"
      placeholder="Количество возможных применений"
      class="mb-16"
    />

    <v-input
      v-model="data.startsAt"
      placeholder="Начало действия"
      stack-placeholder
      :input-attrs="{
        type: 'datetime-local',
        placeholder: ' ',
      }"
      class="mb-16"
    />

    <v-input
      v-model="data.endsAt"
      placeholder="Конец действия"
      stack-placeholder
      :input-attrs="{
        type: 'datetime-local',
        min: data.startsAt,
      }"
      class="mb-16"
    />

    <v-textarea
      v-model="data.comment"
      placeholder="Комментарий"
      :rows="3"
    />

    <v-modal-footer class="mt-24">
      <v-button
        :loading="submitting"
        :disabled="!canSubmit"
        @click="submit"
      >
        {{ action }}
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const options = useModalOptions<"add-free-access-promo-code" | "edit-free-access-promo-code">()
const action = computed(() => (options.value.id ? "Изменить" : "Добавить"))
const heading = computed(() => action.value + " промокод")

const data = reactive({
  code: "",
  startsAt: "",
  endsAt: "",
  usageLimit: undefined as undefined | number,
  days: undefined as undefined | number,
  comment: "",
  isPcre: false,
})

if (options.value.id) {
  const {
    data: { data: promoCodes },
  } = await findFreeAccessPromoCodes()
  const promoCode = promoCodes.find(({ id }) => id === options.value.id)

  if (promoCode) {
    data.code = promoCode.code
    data.startsAt = new Date(+promoCode.starts_at * 1000).toISOString().slice(0, 16)
    data.endsAt = new Date(+promoCode.ends_at * 1000).toISOString().slice(0, 16)
    data.usageLimit = +promoCode.usage_limit
    data.days = +promoCode.days
    data.comment = promoCode.comment
    data.isPcre = Boolean(+promoCode.is_pcre)
  }
}

function formatDateStringToTimestamp(string: string) {
  return Math.floor(+new Date(string) / 1000)
}
const processedData = computed(() => ({
  ...data,
  startsAt: formatDateStringToTimestamp(data.startsAt),
  endsAt: formatDateStringToTimestamp(data.endsAt),
}))

const canSubmit = computed(() => data.code && data.startsAt && data.endsAt && data.usageLimit)
const submitting = ref(false)

const modals = useModalsStore()
const eventBus = useEventBus()

async function submit() {
  if (!canSubmit.value) {
    return
  }

  submitting.value = true

  const response = await editFreeAccessPromoCode({
    id: options.value.id,
    ...processedData.value,
  })

  submitting.value = false

  if (!response.data.success) {
    return
  }

  eventBus.emit("promoCodeEdited")
  modals.hide()
}
</script>
