<template>
  <div
    v-if="size === 'md'"
    class="flex w-fit min-w-[260px] gap-12 rounded-12 border border-e1e1e9 px-12 py-16"
  >
    <v-asset-icon
      :type-id="instrument.trade0instrument0type_id"
      class="my-auto basis-36"
    />

    <div class="font-medium">
      <p class="text-14">{{ instrument.name }}</p>
      <p class="text-13 text-84849b">
        <span>{{ instrument.secid || instrument.isin }}</span>
        <template v-if="instrument.lotsize">
          <span class="mx-6 mt-2 inline-block h-7 w-7 rounded-full bg-c7c6cd"></span>
          <span class="whitespace-nowrap">мин. {{ formatLotSize(instrument.lotsize) }} шт.</span>
        </template>
      </p>
    </div>
  </div>
  <div
    v-else
    class="flex items-center gap-16"
  >
    <v-asset-icon
      :type-id="instrument.trade0instrument0type_id"
      class="basis-36"
    />
    <div>
      <p class="mb-2 text-26 font-bold">{{ instrument.name }}</p>
      <p class="text-13 font-medium text-84849b">
        <span>{{ instrument.secid || instrument.isin }}</span>
        <template v-if="instrument.lotsize">
          <span class="mx-6 mt-2 inline-block h-7 w-7 rounded-full bg-c7c6cd"></span>
          <span class="whitespace-nowrap">мин. {{ formatLotSize(instrument.lotsize) }} шт.</span>
        </template>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id: number | string
    size?: "md" | "lg"
  }>(),
  {
    size: "md",
  }
)

const instrumentsStore = useInstrumentsStore()
const instrument = await instrumentsStore.getInstrument(props.id)
</script>
