import { DiscountPromoCode, FreeAccessPromoCode, PromoCodeValidation } from "~/models/api"

export function findFreeAccessPromoCodes() {
  return get<FreeAccessPromoCode[]>("/promoCodes/find/")
}

export function findDiscountPromoCodes() {
  return get<DiscountPromoCode[]>("/discountPromoCodes/find/")
}

export function editFreeAccessPromoCode({
  id,
  code,
  startsAt,
  endsAt,
  usageLimit,
  days,
  comment,
  isPcre,
}: {
  id?: string
  code: string
  startsAt: number
  endsAt: number
  usageLimit?: number
  days?: number
  comment: string
  isPcre: boolean
}) {
  return post<FreeAccessPromoCode>("/promoCodes/edit/", {
    id,
    code,
    starts_at: startsAt,
    ends_at: endsAt,
    usage_limit: usageLimit,
    days,
    comment,
    is_pcre: Number(isPcre),
  })
}

export function editDiscountPromoCode({
  id,
  code,
  startsAt,
  endsAt,
  usageLimit,
  months,
  percents,
  comment,
  isPcre,
}: {
  id?: string
  code: string
  startsAt: number
  endsAt: number
  usageLimit?: number
  months?: number
  percents?: number
  comment: string
  isPcre: boolean
}) {
  return post<DiscountPromoCode>("/discountPromoCodes/edit/", {
    id,
    code,
    starts_at: startsAt,
    ends_at: endsAt,
    usage_limit: usageLimit,
    months,
    percents,
    comment,
    is_pcre: Number(isPcre),
  })
}

export function deleteFreeAccessPromoCode(id: string) {
  return post("/promoCodes/delete/", { id })
}

export function deleteDiscountPromoCode(id: string) {
  return post("/discountPromoCodes/delete/", { id })
}

export function validatePromoCode(code: string) {
  return post<PromoCodeValidation>("/validations/validatePromoCode/", {
    code,
  })
}
