export default defineNuxtRouteMiddleware((to, from) => {
  const user = useUserStore()
  const requiredRole = (to.meta.requiredRole as string) || "user"

  if (requiredRole === "any" || requiredRole === user.role) {
    return
  }

  const nextPath = getNextPath(requiredRole)
  if (!nextPath) {
    return
  }

  if (requiredRole === "user") {
    const user = useUserStore()
    user.loginRedirectPath = to.fullPath
  }

  if (nextPath === from.path) {
    return abortNavigation()
  }

  return navigateTo(nextPath)
})

function getNextPath(requiredRole: string) {
  switch (requiredRole) {
    case "user":
      return "/auth"
    case "guest":
      return "/"
  }
}
