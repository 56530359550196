<template>
  <modal-shared-portfolio-goal />

  <v-modal-footer class="mt-24">
    <v-button
      :disabled="!isValid"
      :loading="submitting"
      @click="submit"
    >
      {{ portfolioStore.hasPlan ? "Изменить план" : "Создать план" }}
    </v-button>
  </v-modal-footer>
</template>

<script setup lang="ts">
import { InvestmentFrequency } from "~/models/api"

const baseInstrument = useBaseInstrument()

const portfolioStore = usePortfolioStore()

const distributionByTypes = inject("distributionByTypes") as Ref<Record<string, number>>
const distributionByInstruments = inject("distributionByInstruments") as Ref<Record<string, number>>
const targetBalance = inject("targetBalance") as Ref<number>
const recurringInvestmentAmount = inject("recurringInvestmentAmount") as Ref<number>
const investmentFrequency = inject("investmentFrequency") as Ref<InvestmentFrequency>

const submitting = ref(false)

const isValid = computed(() => targetBalance.value && recurringInvestmentAmount.value && investmentFrequency.value)

async function submit() {
  submitting.value = true

  const desiredInstruments = Object.entries(distributionByInstruments.value).map(([id, targetPercents]) => ({ id, targetPercents }))

  if (!desiredInstruments.find(({ id }) => id === baseInstrument.id.value)) {
    desiredInstruments.push({
      id: baseInstrument.id.value,
      targetPercents: 0,
    })
  }

  const response = await editPortfolio({
    id: portfolioStore.portfolio?.id,
    desiredInstruments,
    typesDistribution: Object.entries(distributionByTypes.value).map(([id, targetPercents]) => ({ id, targetPercents })),
    targetBalance: targetBalance.value,
    recurringInvestmentAmount: recurringInvestmentAmount.value,
    investmentFrequency: investmentFrequency.value,
  })

  if (!response.data.success) {
    submitting.value = false
    return
  }

  await portfolioStore.updatePortfolio()

  submitting.value = false

  const modalsStore = useModalsStore()
  modalsStore.hide()
}
</script>
