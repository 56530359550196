<template>
  <div class="flex gap-16 md:gap-8">
    <v-select
      v-model="localEmoji"
      class="whitespace-nowrap"
      :options="icons"
      choose-text="Выберите эмодзи"
      icons
      hide-label
    >
      <template #options="{ choose }">
        <div class="flex flex-wrap gap-x-16 gap-y-10 px-8 md:gap-x-10 md:px-0">
          <v-field-icon
            v-for="icon of icons"
            :key="icon.iconName"
            :name="icon.iconName"
            class="cursor-pointer"
            @click="choose(icon)"
          />
        </div>
      </template>
    </v-select>
    <v-input
      v-model="name"
      class="grow"
      placeholder="Название портфеля"
      required
    />
  </div>
</template>

<script setup lang="ts">
const name = inject("name") as Ref<string>
const emoji = inject("emoji") as Ref<string>

const icons = [
  { iconName: "💰", value: "💰" },
  { iconName: "🥳", value: "🥳" },
  { iconName: "😀", value: "😀" },
  { iconName: "😌", value: "😌" },
  { iconName: "😛", value: "😛" },
  { iconName: "🤪", value: "🤪" },
  { iconName: "😎", value: "😎" },
  { iconName: "🤓", value: "🤓" },
]

const localEmoji = computed({
  get() {
    return icons.find((icon) => icon.value === emoji.value) as (typeof icons)[number]
  },
  set(option: (typeof icons)[number]) {
    emoji.value = option.iconName
  },
})
</script>
