<template>
  <v-modal mobile-type="normal">
    <h2 class="mb-16 text-22 font-bold">{{ title }}</h2>
    <p class="mb-24">{{ confirmation }}</p>
    <v-modal-footer class="!justify-start">
      <v-button
        variant="danger"
        :loading="submitting"
        @click="submit"
      >
        Да, удалить
      </v-button>
      <v-button
        variant="outlined-without-bg"
        @click="cancel"
      >
        Нет, отменить
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const modalsStore = useModalsStore()
const options = useModalOptions<"delete-user">()

function cancel() {
  modalsStore.hide()
}

const title = computed(() => (options.value.id ? "Удаление пользователя" : "Удаление аккаунта"))
const confirmation = computed(() => "Вы уверены, что хотите удалить " + (options.value.id ? "пользователя" : "аккаунт") + "?")

const submitting = ref(false)
async function submit() {
  submitting.value = true

  const response = await deleteUser({ id: options.value.id })

  if (!response.data.success) {
    submitting.value = false
    return
  }

  if (!options.value.id) {
    const user = useUserStore()
    user.logout()
  }

  modalsStore.hide()
}
</script>
