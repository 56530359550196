<template>
  <v-modal mobile-type="normal">
    <v-modal-heading class="mb-24">Настройки пользователя</v-modal-heading>
    <v-modal-footer>
      <v-button
        variant="outlined-with-bg"
        class="text-f83d3d"
        @click="modals.show('delete-user', { id: undefined })"
      >
        Удалить аккаунт
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
const modals = useModalsStore()
</script>
