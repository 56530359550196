<template>
  <Transition>
    <div
      v-if="props.visible"
      class="absolute top-[calc(100%+5px)] z-30 flex max-h-[300px] min-w-full rounded-12 border border-c7c6cd bg-white shadow-lg shadow-black/10"
      :class="props.class"
    >
      <slot />
    </div>
  </Transition>
</template>

<script setup lang="ts">
export interface VSelectDropdownPopperProps {
  visible?: boolean
  class?: string
}

const props = defineProps<VSelectDropdownPopperProps>()
</script>
