import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { Response } from "~/models/api"

function getClient() {
  const config = useRuntimeConfig()
  const token = useAPIToken()

  const client = axios.create({
    baseURL: config.public.apiUrl,
    headers: {
      Authorization: token.value ? `Bearer ${token.value}` : null,
    },
  })

  return client
}

function handleResponseErrors(response: AxiosResponse<Response>, silent?: boolean) {
  if (!response.data.errors?.length || silent) {
    return
  }

  for (const error of response.data.errors) {
    alert(translateError(error))
  }
}

interface Options extends AxiosRequestConfig {
  silent?: boolean
}

export async function get<T>(url: string, options: Options = {}): Promise<AxiosResponse<Response<T>>> {
  const client = getClient()
  const response = await client.get<Response<T>>(url, options)
  handleResponseErrors(response, options.silent)
  return response
}

export async function post<T>(url: string, data?: unknown, options: Options = {}): Promise<AxiosResponse<Response<T>>> {
  const client = getClient()
  const response = await client.post<Response<T>>(url, data, options)
  handleResponseErrors(response, options.silent)
  return response
}

export function translateError(error: string) {
  const errors: Record<string, string> = {
    ERROR_TARGET_PERCENTS_ARE_BELOW_ZERO: "Скорректируйте целевое распределение, одно из значений меньше 0",
    ERROR_QUANTITY_IS_TOO_HIGH: "Количество бумаг слишком высокое для доступной суммы свободных средств",
    ERROR_QUANTITY_IS_BELOW_ZERO: "Количество бумаг меньше 0",
    ERROR_INSTRUMENT_QUANTITY_IS_INVALID_SEE_LOTSIZE: "Вы хотите купить неполный лот. Обратите внимание на размер лота",
    ERROR_CANT_SELL_MORE_THAN_OWN: "Вы хотите продать больше бумаг, чем есть в портфеле",
    ERROR_TOTAL_INSTRUMENT_TARGET_PERCENTS_ARE_NOT_100: "Сумма всех активов в портфеле не равна 100%, проверьте распределение",
    ERROR_PORTFOLIO_DOESNT_EXIST_OR_NOT_YOURS: "Такого портфеля не существует",
    ERROR_BASE_INSTRUMENT_DOESNT_EXIST: "Такого базового актива не существует",
    ERROR_PORTFOLIO_NAME_IS_MISSING: "Задайте имя вашего портфеля",
    ERROR_PORTFOLIO_EMOJI_IS_MISSING_OR_IS_NOT_EMOJI: "Задайте эмодзи вашего портфеля",
    ERROR_TRADE_INSTRUMENT_TYPE_ID_DOES_NOT_EXIST: "Такого инструмента не существует",
    ERROR_PORTFOLIO_HAS_NO_WISHES: "Задайте целевое изменение активов портфеля",
    ERROR_NOT_ENOUGH_EQUITY_FOR_REBALANCE: "Недостаточно свободных средств",
    ERROR_AUTH_REQUIRED: "Пройдите авторизацию",
    ERROR_INVALID_INPUT: "Некорректный ввод данных",
    ERROR_NO_PERMISSIONS: "У вас нет доступа к данной странице/ресурсу",
    ERROR_EQUITY_IS_BELOW_ZERO: "Баланс не может быть отрицательным",
    ERROR_INVALID_PROMO_CODE: "Промокод не найден",
    ERROR_PREMIUM_IS_ALREADY_ACTIVE: "Подписка уже оформлена",
    ERROR_PROMO_CODE_HAS_EXPIRED: "Срок действия промокода истек",
    ERROR_PROMO_CODE_CAN_BE_USED_ONLY_FOR_MONTH: "Промокод может быть использовать только для помесячной оплаты",
    ERROR_UNABLE_TO_INITIATE_PAYMENT: "Ошибка при попытке оплаты",
    ERROR_USER_BANNED: "Ваш аккаунт был заблокирован",
  }

  return errors[error] || error
}
