<template>
  <v-input
    v-model="rawValue"
    v-model:is-focused="isFocused"
    :allowed-symbols="/\d|\s|,|\./"
    :input-attrs="{ inputmode: $props.maximumFractionDigits ? 'decimal' : 'numeric' }"
  />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: null | number
    minimumFractionDigits?: number
    maximumFractionDigits?: number
    suffix?: string
  }>(),
  {
    modelValue: null,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    suffix: "",
  }
)

const emit = defineEmits<{
  "update:modelValue": [value: null | number]
}>()

const rawValue = ref("")
watch(rawValue, () => {
  const number = parseNumber(rawValue.value)
  emit("update:modelValue", Number.isNaN(number) ? null : number)
})

const isFocused = ref(false)
watch(isFocused, () => {
  if (isFocused.value) {
    return
  }

  rawValue.value = formatNumber(parseNumber(rawValue.value))
})

watch(
  () => props.modelValue,
  () => {
    if (isFocused.value) {
      return
    }

    const newRawValue = props.modelValue === null ? "" : formatNumber(props.modelValue)

    if (newRawValue === rawValue.value) {
      return
    }

    rawValue.value = newRawValue
  },
  {
    immediate: true,
  }
)

function parseNumber(string: string): number {
  const match = string
    .replace(/\s/g, "")
    .replace(",", ".")
    .match(/\d+\.?\d*/)

  if (!match) {
    return NaN
  }

  const numberInString = match[0]
  const [integerPart = "0", fractionalPart = "0"] = numberInString.split(".")

  return Number(integerPart + "." + fractionalPart.slice(0, props.maximumFractionDigits))
}

function formatNumber(number: number) {
  if (Number.isNaN(number)) {
    return ""
  }

  return (
    new Intl.NumberFormat("ru-RU", {
      minimumFractionDigits: props.minimumFractionDigits,
      maximumFractionDigits: props.maximumFractionDigits,
    }).format(number) + props.suffix
  )
}
</script>
