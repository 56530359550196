<template>
  <v-select
    v-model="activeOption"
    v-model:search-query="query"
    :options="options"
    :placeholder="placeholder"
    searchable
    search-autofocus
    choose-text="Выберите актив"
    search-placeholder="Найти актив"
    :searching="searching"
    :no-options-text="madeRequest ? 'Активов не найдено' : ''"
    required
    :bottom-sheet-props="{
      fullHeight: true,
    }"
    :popper-props="{
      class: '!min-w-[unset] w-[300px]',
    }"
  >
    <template #icon>
      <v-asset-icon
        :type-id="activeInstrument?.trade0instrument0type_id"
        class="mt-1"
      />
    </template>
    <template #options="{ choose }">
      <div class="flex flex-col gap-4">
        <div
          v-for="[typeId, optionsGroup] of entries"
          :key="typeId"
        >
          <span class="mb-4 inline-block px-14 font-medium md:px-0">{{ instrumentsStore.types[typeId] }}</span>

          <v-select-option
            v-for="option in optionsGroup"
            :key="option.value"
            :secondary-label="option.secondaryLabel"
            @click="choose(option), instrumentsStore.saveInstrument(option.instrument)"
          >
            <template #icon>
              <v-asset-icon
                :type-id="typeId"
                class="shrink-0"
              />
            </template>

            {{ option.label }}
          </v-select-option>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script setup lang="ts">
import { SelectOption } from "~/components/base/v-select/v-select.vue"
import { InstrumentType, TradeInstrument } from "~/models/api"

const props = defineProps<{
  modelValue?: null | string
  hideLotSize?: boolean
}>()

const emit = defineEmits<{
  "update:modelValue": [value: null | string]
}>()

const instrumentsStore = useInstrumentsStore()
await instrumentsStore.getTypes()

// TODO add loading spinner
const activeOption = ref<null | SelectOption<string>>(null)
watch(activeOption, () => {
  const id = activeOption.value?.value
  if (id) {
    instrumentsStore.loadInstrument(id)
  }
  emit("update:modelValue", id || null)
})
if (props.modelValue) {
  instrumentsStore.getInstrument(props.modelValue).then((instrument) => {
    activeOption.value = getInstrumentOption(instrument)
  })
}

const activeInstrument = ref<null | TradeInstrument>(null)
watch(
  activeOption,
  async () => {
    if (!activeOption.value) {
      activeInstrument.value = null
      return
    }

    activeInstrument.value = await instrumentsStore.getInstrument(activeOption.value.value)
  },
  {
    immediate: true,
  }
)

const placeholder = computed(() => {
  if (props.hideLotSize || !activeInstrument.value || !activeInstrument.value.lotsize) {
    return "Актив"
  }

  return `Актив (мин. ${formatLotSize(activeInstrument.value.lotsize)} шт.)`
})

type Option = SelectOption<string> & { instrument: TradeInstrument }
const options = ref<Option[]>([])
const optionsGroupedByTypeId = computed(() => {
  return options.value.reduce(
    (result, option) => {
      if (result[option.instrument.trade0instrument0type_id]) {
        result[option.instrument.trade0instrument0type_id].push(option)
      } else {
        result[option.instrument.trade0instrument0type_id] = [option]
      }

      return result
    },
    {} as Record<string, Option[]>
  )
})
const typesSorting: string[] = [
  InstrumentType.Stocks,
  InstrumentType.Funds,
  InstrumentType.Currency,
  InstrumentType.Crypto,
  InstrumentType.Goods,
  InstrumentType.RealEstate,
  InstrumentType.Bonds,
]
const collator = new Intl.Collator("en", { ignorePunctuation: true })
const entries = computed<[string, Option[]][]>(() =>
  Object.entries(optionsGroupedByTypeId.value)
    .sort(([a], [b]) => typesSorting.indexOf(a) - typesSorting.indexOf(b))
    .map(([key, options]) => [key, options.sort((a, b) => collator.compare(a.label || "", b.label || ""))])
)

const searching = ref(false)
const query = ref("")
const madeRequest = ref(false)

let controller = new AbortController()

watch(query, () => {
  searching.value = true
})

watchDebounced(
  query,
  async () => {
    if (!query.value.length) {
      options.value = []
      searching.value = false
      return
    }

    searching.value = true

    controller.abort()
    controller = new AbortController()

    try {
      const response = await get<TradeInstrument[]>("/tradeInstruments/find/", {
        signal: controller.signal,
        params: {
          q: query.value,
        },
        silent: true,
      })

      const instruments = response.data.data

      if (instruments) {
        options.value = instruments.map(getInstrumentOption)
        return
      }

      options.value = []
    } catch {
      options.value = []
    } finally {
      madeRequest.value = true
      searching.value = false
    }
  },
  {
    debounce: 750,
  }
)

function getInstrumentOption(instrument: TradeInstrument) {
  return {
    value: instrument.id,
    label: instrument.name,
    secondaryLabel: instrument.secid ? `${instrument.secid} (${instrument.isin})` : instrument.isin,
    instrument,
  }
}
</script>
