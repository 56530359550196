<template>
  <v-modal mobile-type="normal">
    <v-modal-heading class="mb-24 md:mb-16">Управление подпиской</v-modal-heading>

    <dl>
      <dt
        v-if="!user.isPremiumCancelled"
        class="mb-8 text-424250"
      >
        Следующее обновление подписки
      </dt>
      <dt
        v-else
        class="mb-8 text-424250"
      >
        Подписка действует до
      </dt>
      <dd class="text-18 font-bold">{{ user.premiumExpirationDate.toLocaleDateString() }}</dd>

      <template v-if="!user.isPremiumCancelled">
        <dt class="mb-8 mt-24 text-424250">Сумма подписки</dt>
        <dd class="text-18 font-bold">
          {{ nextPaymentPrice.toLocaleString() }} {{ getRussianNounForm(nextPaymentPrice, "рубль", "рубля", "рублей") }}
        </dd>
      </template>
    </dl>

    <v-modal-footer
      v-if="!user.isPremiumCancelled"
      class="mt-24"
    >
      <v-button
        variant="outlined-with-bg"
        class="text-f83d3d"
        @click="modals.show('cancel-subscription', {})"
      >
        Отменить подписку
      </v-button>
    </v-modal-footer>
  </v-modal>
</template>

<script setup lang="ts">
import { Payment } from "~/models/api"

const user = useUserStore()
const modals = useModalsStore()

let activePayment: Payment

if (!user.isPremiumCancelled) {
  const response = await findPayments({ id: user.details.active_purchase_id as string })
  activePayment = response.data.data[0]
}

const nextPaymentPrice = computed(() => {
  if (!activePayment) {
    return 0
  }

  if (!activePayment.code_effects || "free_days" in activePayment.code_effects) {
    return +activePayment.original_price / 100
  }

  const premiumExpiresAt = +activePayment.expires_at * 1000
  const discountExpiresAt = +(user.details.discount_expires_at || 0) * 1000
  if (premiumExpiresAt <= discountExpiresAt) {
    return +activePayment.price / 100
  }

  return +activePayment.original_price / 100
})
</script>
