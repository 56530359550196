<!-- transform-gpu because otherwise icon is shivering when animating opacity with transition in webkit????? -->
<template>
  <button
    class="relative flex transform-gpu items-center justify-center overflow-hidden border font-medium transition-[opacity,background-color]"
    :class="{
      'gap-10 rounded-12 py-13 text-16': props.size === 'md',
      'px-18': props.size === 'md' && $slots.default,
      'px-14': props.size === 'md' && !$slots.default,
      'gap-6 rounded-8 py-8 text-13': props.size === 'sm',
      'px-12': props.size === 'sm' && $slots.default,
      'px-10': props.size === 'sm' && !$slots.default,

      'border-232335 bg-232335 text-white': props.variant === 'default',
      'hover:bg-232335/80': props.variant === 'default' && !loading,

      'border-2c58f3 bg-2c58f3 text-white': props.variant === 'primary',
      'hover:bg-2c58f3/80': props.variant === 'primary' && !loading,

      'border-f83d3d bg-f83d3d text-white': props.variant === 'danger',
      'hover:bg-f83d3d/80': props.variant === 'danger' && !loading,

      'border-ffdad2 text-f83d3d': props.variant === 'danger-outlined',
      'hover:bg-f83d3d/5': props.variant === 'danger-outlined' && !loading,

      'border-13a906 bg-13a906 text-white': props.variant === 'success',
      'hover:bg-13a906/80': props.variant === 'success' && !loading,

      'border-f0f1f4 bg-f0f1f4 text-232335': props.variant === 'secondary',
      'hover:bg-f0f1f4/20': props.variant === 'secondary' && !loading,

      'border-e6e7ec bg-white text-232335': props.variant === 'outlined-with-bg',
      'hover:bg-f0f1f4/40': props.variant === 'outlined-with-bg' && !loading,

      'border-c4c8dd text-232335': props.variant === 'outlined-without-bg',
      'hover:bg-c4c8dd/20': props.variant === 'outlined-without-bg' && !loading,

      'cursor-not-allowed opacity-50': props.disabled,
      'cursor-wait': props.loading,
    }"
    @click="onClick"
  >
    <Icon
      v-if="props.icon"
      :size="props.size === 'md' ? '24px' : '16px'"
      class="shrink-0"
      :name="props.icon"
    />
    <span v-if="$slots.default">
      <slot />
    </span>
    <Transition>
      <div
        v-if="loading"
        class="absolute inset-0 flex items-center justify-center"
        :class="{
          'bg-232335/95': props.variant === 'default',
          'bg-2c58f3/95': props.variant === 'primary',
          'bg-f83d3d/95': props.variant === 'danger',
          'bg-13a906/95': props.variant === 'success',
          'bg-f0f1f4/95': props.variant === 'secondary',
          'bg-white/95': props.variant === 'outlined-with-bg',
        }"
      >
        <v-spinner
          :class="{
            'border-white/30 border-l-white':
              props.variant === 'default' || props.variant === 'primary' || props.variant === 'danger' || props.variant === 'success',
            'border-232335/30 border-l-232335': props.variant === 'secondary' || props.variant === 'outlined-with-bg',
          }"
          size="25px"
          thickness="3px"
        />
      </div>
    </Transition>
  </button>
</template>

<script setup lang="ts">
export interface VButtonProps {
  icon?: string
  variant?: "default" | "primary" | "secondary" | "outlined-with-bg" | "outlined-without-bg" | "danger" | "danger-outlined" | "success"
  size?: "md" | "sm"
  disabled?: boolean
  loading?: boolean
}

const props = withDefaults(defineProps<VButtonProps>(), {
  variant: "default",
  size: "md",
  disabled: false,
  loading: false,
})

const emit = defineEmits<{
  (e: "click", originalEvent: MouseEvent): void
}>()

function onClick(event: MouseEvent) {
  if (!props.loading && !props.disabled) {
    emit("click", event)
  }
}
</script>
