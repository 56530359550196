<template>
  <v-select-dropdown-wrapper
    v-model:visible="isVisible"
    :bottom-sheet-props="props.bottomSheetProps"
    :popper-props="props.popperProps"
    @blur-search-input="blurSearchInput"
    @try-focus-on-search-input="tryFocusOnSearchInput"
  >
    <div class="flex w-full flex-col items-stretch">
      <div
        v-if="heading || $props.searchable"
        class="flex flex-col gap-8 pb-8"
        :class="{
          'px-12 pt-12': !isMobile,
        }"
      >
        <span
          v-if="heading"
          class="font-medium text-black"
        >
          {{ heading }}
        </span>

        <v-basic-input
          v-if="$props.searchable"
          ref="search"
          v-model="searchQuery"
          :placeholder="$props.searchPlaceholder"
          icon="tabler:search"
          :loading="$props.searching"
        />
      </div>

      <div
        v-if="$props.options?.length"
        class="grow overflow-auto pb-8 pt-6"
      >
        <slot
          name="options"
          :choose="choose"
        >
          <v-select-option
            v-for="option in $props.options"
            :key="option.value as string"
            :icon-path="$props.icons ? option.iconPath : undefined"
            :icon-name="$props.icons ? option.iconName : undefined"
            :secondary-label="option.secondaryLabel"
            :class="{
              'md:not-last-child:mb-16': !$props.icons,
            }"
            @click="choose(option)"
          >
            {{ option.label }}
          </v-select-option>
        </slot>
      </div>
      <p
        v-else
        class="pb-12"
        :class="{
          'px-12': !isMobile,
        }"
      >
        {{ noOptionsText }}
      </p>
    </div>
  </v-select-dropdown-wrapper>
</template>

<script setup lang="ts">
import { SelectOption } from "./v-select.vue"
import VBasicInput from "../v-basic-input.vue"
import { VBottomSheetProps } from "../v-bottom-sheet/v-bottom-sheet.vue"
import { VSelectDropdownPopperProps } from "./v-select-dropdown-popper.vue"

const props = defineProps<{
  modelValue: null | SelectOption
  visible?: boolean
  options: SelectOption[]
  icons?: boolean
  searchable?: boolean
  searchAutofocus?: boolean
  searching?: boolean
  searchPlaceholder?: string
  searchQuery: string
  placeholder?: string
  chooseText?: string
  noOptionsText?: string
  styles?: {
    desktopDropdownWidth?: string
  }
  bottomSheetProps?: Partial<VBottomSheetProps>
  popperProps?: Partial<VSelectDropdownPopperProps>
}>()

const emit = defineEmits<{
  "update:modelValue": [value: SelectOption]
  "update:visible": [value: boolean]
  "update:searchQuery": [value: string]
}>()

const isVisible = computed({
  get() {
    return props.visible ?? false
  },
  set(newValue: boolean) {
    emit("update:visible", newValue)
  },
})

const search = ref<typeof VBasicInput>()

const searchQuery = computed({
  get() {
    return props.searchQuery
  },
  set(newValue: string) {
    emit("update:searchQuery", newValue)
  },
})

const heading = computed(() => {
  if (!isMobile.value) {
    return props.chooseText
  }

  return props.chooseText || props.placeholder
})

const screen = useScreen()
const isMobile = computed(() => screen.value.md)

function hide() {
  emit("update:visible", false)
}

function choose(value: SelectOption) {
  emit("update:modelValue", value)
  hide()
}

const { isIOS } = usePlatform()

function tryFocusOnSearchInput() {
  if (props.searchAutofocus && !isIOS) {
    search.value?.focus()
  }
}

function blurSearchInput() {
  search.value?.blur()
}
</script>
